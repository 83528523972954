/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'

import * as APIController from '../../../api/APIController.js';
import * as yup from 'yup';

import Notification from '../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

const CampaignForm = props => {
    const initialValues = { name: '', brand: '', allproduct: '', orderno: '', rawAssetUrl: '', instructionsUrl: '', active: 1, users: [], allUsers: false, }

    const [IsLoading, setIsLoading] = React.useState(false);
    const [Loading, setLoading] = React.useState(true);
    const [LoadingProducts, setLoadingProducts] = React.useState(true);
    const [dataSource, setdataSource] = React.useState([]);
    const [sourceProducts, setsourceProducts] = React.useState([]);
    const [orderData, setOrderData] = React.useState([]);

    React.useEffect(() => {
        if (props.history.location.state.campaignIdEdit != 0) {
            initialValues.name = props.GetCampaignData[0].Name
            initialValues.brand = props.GetCampaignData[0].BrandID
            initialValues.orderno = props.GetCampaignData[0].OrderNo
            initialValues.rawAssetUrl = props.GetCampaignData[0].RawAssetURL
            initialValues.instructionsUrl = props.GetCampaignData[0].InstructionsURL
            initialValues.active = props.GetCampaignData[0].IsLive ? 1 : 0
            setdataSource(JSON.parse(props.GetCampaignData[0].Brands));
            setsourceProducts(JSON.parse(props.GetCampaignData[0].Products));
            setLoadingProducts(false)
            setLoading(false)
        }
        else {
            wsGetBrandForDropdown();
            wsGetProductForDropdown();
        }
        var pairs = [];
        for (let i = 0; i < 100; i++) {
            pairs.push({ ID: i + 1 });
        }
        setOrderData(pairs)
    }, [])

    // const wsGetCampaignByID = (ID) => {
    //     APIController.GetCampaignByID(ID)
    //         .then((response) => {
    //             setLoading(false)
    //             setLoadingProducts(false)
    //             if (response.error == null) {
    //                 initialValues.name = response.object.data[0].Name
    //                 initialValues.brand = response.object.data[0].BrandID
    //                 initialValues.orderno = response.object.data[0].OrderNo
    //                 initialValues.rawAssetUrl = response.object.data[0].RawAssetURL
    //                 initialValues.active = response.object.data[0].IsLive ? 1 : 0

    //                 setdataSource(JSON.parse(response.object.data[0].Brands));
    //                 setsourceProducts(JSON.parse(response.object.data[0].Products));

    //             }
    //             else if (response.error.message && response.error.message.length > 0) {
    //                 handleNotification(response.error.message, true);
    //             }
    //         })
    // }

    const wsGetBrandForDropdown = () => {
        APIController.GetBrandForDropdown()
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    setdataSource(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetProductForDropdown = () => {
        APIController.GetProductForDropdown()
            .then((response) => {
                setLoadingProducts(false)
                if (response.error == null) {
                    // initialValues.product = response.object.data
                    setsourceProducts(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    // const wsAddEditCampaign = (ID, Name, BrandID, OrderNo, RawAssetURL, Products, SendRawURLLink, IsLive) => {
    //     setIsLoading(true)
    //     if (SendRawURLLink == '1' && RawAssetURL == '') {
    //         handleNotification('Google Drive URL is required', true);
    //         setIsLoading(false)
    //     }
    //     else if (Products == '') {
    //         handleNotification('Products  is required', true);
    //         setIsLoading(false)
    //     }
    //     else {
    //         APIController.AddEditCampaign(ID, Name, BrandID, OrderNo, RawAssetURL, Products, SendRawURLLink, IsLive)
    //             .then((response) => {
    //                 setIsLoading(false)
    //                 if (response.object.status == 1) {
    //                     if (isButtonNav) {
    //                         props.history.push({
    //                             pathname: '/campaigns/add',
    //                             state: { campaignIdEdit: CampaignId }
    //                         })
    //                         props.toUploadAsset()
    //                         setButtonNav(false)
    //                     } else {
    //                         props.history.replace({
    //                             pathname: '/campaigns',
    //                             state: { showMessage: response.object.message }
    //                         })
    //                     }

    //                 } else {
    //                     handleNotification(response.object.message, true);
    //                 }
    //             })
    //     }
    // }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    return (
        <>
            <section className="tab-content asset-tab-wrapper">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateCampaignForm}
                    validateOnMount={false}
                    enableReinitialize={false}
                    onSubmit={(values) => {
                        const data = sourceProducts.filter(function (temp) {
                            return temp.IsSelected == 1
                        }).map((data) => { return data.ID });
                        props.AddEditCampaign(values, data.toString())
                        // if (isButtonValue === false) {
                        //     wsAddEditCampaign(props.history.location.state.campaignIdEdit, values.name, values.brand, values.orderno, values.rawAssetUrl, data.toString(), '0', values.active)
                        // }
                        // else {
                        //     wsAddEditCampaign(props.history.location.state.campaignIdEdit, values.name, values.brand, values.orderno, values.rawAssetUrl, data.toString(), '1', values.active)
                        // }
                    }} >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    {/* <div className="form_field"> */}
                                    <div
                                        className={`form_field ${errors &&
                                            touched.name &&
                                            errors.name &&
                                            errors.name !== '' ? 'error' : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Campaign Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Campaign Name" name="name" value={values.name} maxLength="50" id={'name'} />
                                            {errors &&
                                                touched.name &&
                                                errors.name &&
                                                errors.name !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='name'
                                                            render={(err) => { return err; }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors &&
                                        touched.brand &&
                                        errors.brand &&
                                        errors.brand !== '' ? 'error' : ''
                                        }`}>
                                        <div className="field-left">
                                            <label>Brand Name</label>
                                        </div>
                                        <div className="field-right">
                                            {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                                :
                                                <select name='brand'
                                                    id={'brand'}
                                                    defaultValue={values.brand}
                                                    value={values.brand}
                                                    onChange={(e) => {
                                                        setFieldValue('brand', e.target.value);
                                                    }}
                                                >
                                                    <option value={''}>Select Brand</option>
                                                    {
                                                        dataSource.map((obj, index) => {
                                                            return <option selected={obj.ID == values.brand ? obj.ID : obj.Name} value={obj.ID} key={index}>{obj.Name}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                            {errors &&
                                                touched.brand &&
                                                errors.brand &&
                                                errors.brand !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='brand'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Products</label>
                                        </div>
                                        {LoadingProducts ? <CircularProgress style={{ alignItems: 'center' }} />
                                            :
                                            <div className="field-right">

                                                {/* <div className='users-info-col'> */}
                                                <div className='info-head'>
                                                    <div className='custom-checkbox'>
                                                        <label htmlFor='allUsers'>Products</label>
                                                    </div>
                                                </div>
                                                <div className='info-body' style={{ display: 'table', width: '100%' }}>
                                                    {sourceProducts.map((obj, index) => {
                                                        return (
                                                            <div className='info-body-row' key={index} style={{ float: 'left', width: '50%' }}>
                                                                <div className='custom-checkbox'>
                                                                    <Field
                                                                        type='checkbox'
                                                                        name={`user-${obj.ID}`}
                                                                        checked={obj.IsSelected}
                                                                        id={`user-${obj.ID}`}
                                                                        onChange={(e) => {
                                                                            setsourceProducts(sourceProducts.map((item, idx) => item.ID !== obj.ID ? item : { ...item, IsSelected: e.target.checked }))
                                                                        }}
                                                                    />
                                                                    <label htmlFor={`user-${obj.ID}`}>
                                                                        {" "}
                                                                        {obj.Name}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        }
                                    </div>

                                    <div className={`form_field ${errors &&
                                        touched.orderno &&
                                        errors.orderno &&
                                        errors.orderno !== '' ? 'error' : ''
                                        }`}>
                                        <div className="field-left">
                                            <label>Sequence #</label>
                                        </div>
                                        <div className="field-right">
                                            <select
                                                id={'orderno'}
                                                name="reportType"
                                                defaultValue={values.orderno}
                                                value={values.orderno}
                                                onChange={(e) => setFieldValue('orderno', e.target.value)}>
                                                <option>Sequence #</option>
                                                {orderData.map((obj, index) => {
                                                    return <option selected={obj.ID == values.orderno ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
                                                })}
                                            </select>
                                            {errors &&
                                                touched.orderno &&
                                                errors.orderno &&
                                                errors.orderno !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='orderno'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div className={`form_field ${errors &&
                                        touched.rawAssetUrl &&
                                        errors.rawAssetUrl &&
                                        errors.rawAssetUrl !== '' ? 'error' : ''
                                        }`}>
                                        <div className="field-left">
                                            <label>Raw Asset URL</label>
                                        </div>
                                        <div className="field-right">
                                            <Field name="rawAssetUrl" type="text" placeholder="Google Drive URL" value={values.rawAssetUrl} maxLength="50" id={'rawAssetUrl'} />

                                            {errors &&
                                                touched.rawAssetUrl &&
                                                errors.rawAssetUrl &&
                                                errors.rawAssetUrl !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='rawAssetUrl'
                                                            render={(err) => { return err; }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div className={'form_field'}>
                                        <div className="field-left">
                                            <label>Instructions URL</label>
                                        </div>
                                        <div className="field-right">
                                            <Field name="instructionsUrl" type="text" placeholder="Instructions URL" value={values.instructionsUrl} maxLength="50" id={'instructionsUrl'} />
                                        </div>
                                    </div>
                                    {props.history.location.state.campaignIdEdit != 0 ?
                                        <div className="form_field">
                                            <div className="field-left">
                                                <label>Is Live ?</label>
                                            </div>
                                            <div className="field-right">
                                                <div className="custom-radio">
                                                    <input type="radio" name="active" id="yes-text"
                                                        defaultValue={values.active}
                                                        defaultChecked={values.active != 0 ? true : false}
                                                        onClick={(e) => setFieldValue('active', true)} />
                                                    <label htmlFor="yes-text">Yes</label>
                                                </div>
                                                <div className="custom-radio">
                                                    <input type="radio" name="active" id="no-text"
                                                        defaultValue={values.active}
                                                        defaultChecked={values.active == 0 ? true : false}
                                                        onClick={(e) => setFieldValue('active', false)} />
                                                    <label htmlFor="no-text">No</label>
                                                </div>
                                            </div>
                                        </div> : null}

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            {props.history.location.state.campaignIdEdit != 0 ?
                                                <input type="submit" onClick={() => props.isCreate(0)} name="create-campaign" value={IsLoading ? "Updating..." : "Update"} /> :
                                                <input type="submit" onClick={() => props.isCreate(0)} name="create-campaign" value={IsLoading ? "Creating..." : "Create"} />}
                                        </div>
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" onClick={() => props.isCreate(1)} name="create-campaign" value={IsLoading ? "Sending..." : "Send Asset"} />

                                        </div>
                                        {props.history.location.state.campaignIdEdit != 0 ?
                                            <div className='button-field'>
                                                <input type="submit" name="upload-asset" value="upload asset"
                                                    onClick={() => {
                                                        props.isCreate(2)
                                                    }}
                                                />
                                            </div> : null}
                                        <div className="button-field">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>

                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateCampaignForm = yup.object().shape({
    name: yup.string().required('Campaign Name is required'),
    brand: yup.string().required('Brand Name is required'),
    orderno: yup.string().required('Sequence # is required'),
    // rawAssetUrl: yup.string().required('Google Drive URL is required'),
});


export default CampaignForm;