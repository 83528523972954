/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import close_ic from '../../../assets/images/close-ic.svg'
import * as yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';

import Notification from '../../../shared/components/notification/notification';
import * as APIController from '../../../api/APIController';
import Input from 'react-phone-number-input/input'

const UserForm = props => {
    const initialValues = {
        usersFirstname: '',
        usersLastname: '',
        usersEmailAddress: '',
        usersPhoneNo: '',
        active: 1
    }

    React.useEffect(() => {
        if (props.UserID != '0') {
            wsGetUserByID(props.UserID)
        }
    }, [])

    const [dataSource, setdataSource] = React.useState([]);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [Loading, setLoading] = React.useState(false)

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);


    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)
        setTimeout(() => {
            handleClearNotification();
            props.closeModal()
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsGetUserByID = (ID) => {
        setLoading(true)
        APIController.GetUserByID(ID)
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    initialValues.usersFirstname = response.object.data[0].FirstName
                    initialValues.usersLastname = response.object.data[0].LastName
                    initialValues.usersEmailAddress = response.object.data[0].EmailAddress
                    initialValues.usersPhoneNo = response.object.data[0].PhoneNo
                    initialValues.active = response.object.data[0].IsActive ? 1 : 0
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true)
                }
            })
    }

    const wsAddEditUser = (ID, CompanyID, PracticeID, FirstName, LastName, EmailAddress, PhoneNo, IsActive, UserType) => {
        setIsLoading(true)
        APIController.AddEditUser(ID, CompanyID, PracticeID, FirstName, LastName, EmailAddress, PhoneNo, IsActive, UserType)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (response.object.status == 1) {
                        handleNotification(response.object.message)
                    } else {
                        handleNotification(response.object.message, true);
                    }
                    props.handlebind()
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    return (
        <div className="popup-box forgot-popup-box">
            <div className="box-heading">
                <h2 className="h1">{props.Title}</h2>
                <button className="close-btn"><img src={close_ic} alt="icon" onClick={props.closeModal} /></button>
            </div>
            <div className="box-content">
                <Formik
                    enableReinitialize={false}
                    initialValues={initialValues}
                    validationSchema={validateUsersForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        if (!values.usersEmailAddress.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                            handleNotification('Please Enter Valid Email Address', true)
                            document.getElementById('usersEmailAddress').focus();
                        }
                        else if (values.usersPhoneNo.length < 12) {
                            handleNotification('Please Enter Valid Phone Number', true)
                            document.getElementById('usersPhoneNo').focus();
                        }
                        else {
                            wsAddEditUser(props.UserID, '1', props.PracticeID, values.usersFirstname, values.usersLastname, values.usersEmailAddress, values.usersPhoneNo, values.active, '3')
                        }
                    }}
                >
                    {({ handleSubmit, values, setFieldValue, errors, touched, }) => {
                        return (

                            <form onSubmit={handleSubmit}>
                                <div
                                    className={`form_field ${errors &&
                                        touched.usersFirstname &&
                                        errors.usersFirstname &&
                                        errors.usersFirstname !== ''
                                        ? 'error'
                                        : ''
                                        }`}>
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>First Name</label>
                                    </div>
                                    <div className="field-right">
                                        <Field type="text" placeholder="Enter First Name" id={'usersFirstname'} name="usersFirstname" value={values.usersFirstname} maxLength="50" />
                                        {errors &&
                                            touched.usersFirstname &&
                                            errors.usersFirstname &&
                                            errors.usersFirstname !==
                                            '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage
                                                        name='usersFirstname'
                                                        render={(err) => {
                                                            return err;
                                                        }}
                                                    />
                                                </span>
                                            )}
                                    </div>
                                </div>
                                {/* <div className="form_field"> */}
                                <div
                                    className={`form_field ${errors &&
                                        touched.usersLastname &&
                                        errors.usersLastname &&
                                        errors.usersLastname !== ''
                                        ? 'error'
                                        : ''
                                        }`}>
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>Last Name</label>
                                    </div>
                                    <div className="field-right">
                                        <Field type="text" placeholder="Enter Last Name" id={'usersLastname'} name="usersLastname" value={values.usersLastname} maxLength="50" />
                                        {errors &&
                                            touched.usersLastname &&
                                            errors.usersLastname &&
                                            errors.usersLastname !==
                                            '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage
                                                        name='usersLastname'
                                                        render={(err) => {
                                                            return err;
                                                        }}
                                                    />
                                                </span>
                                            )}
                                    </div>
                                </div>

                                <div
                                    className={`form_field ${errors &&
                                        touched.usersEmailAddress &&
                                        errors.usersEmailAddress &&
                                        errors.usersEmailAddress !== ''
                                        ? 'error'
                                        : ''
                                        }`}>
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>Email Address</label>
                                    </div>
                                    <div className="field-right">
                                        <Field type="text" placeholder="Enter Email Address" id={'usersEmailAddress'} name="usersEmailAddress" value={values.usersEmailAddress} maxLength="50" />
                                        {errors &&
                                            touched.usersEmailAddress &&
                                            errors.usersEmailAddress &&
                                            errors.usersEmailAddress !==
                                            '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage
                                                        name='usersEmailAddress'
                                                        render={(err) => {
                                                            return err;
                                                        }}
                                                    />
                                                </span>
                                            )}
                                    </div>
                                </div>
                                <div
                                    className={`form_field ${errors &&
                                        touched.usersPhoneNo &&
                                        errors.usersPhoneNo &&
                                        errors.usersPhoneNo !== ''
                                        ? 'error'
                                        : ''
                                        }`}>
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>Phone No</label>
                                    </div>
                                    <div className="field-right">
                                        <Input country="US" type="text" placeholder="Enter Phone No" id={'usersPhoneNo'} name="usersPhoneNo"
                                            value={values.usersPhoneNo} maxLength="14" onChange={(e) => {
                                                values.usersPhoneNo = e
                                            }} />
                                        {errors &&
                                            touched.usersPhoneNo &&
                                            errors.usersPhoneNo &&
                                            errors.usersPhoneNo !==
                                            '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage
                                                        name='usersPhoneNo'
                                                        render={(err) => {
                                                            return err;
                                                        }}
                                                    />
                                                </span>
                                            )}
                                    </div>
                                </div>

                                {/* <div
                                    className={`form_field ${errors &&
                                        touched.roleid &&
                                        errors.roleid &&
                                        errors.roleid !== ''
                                        ? 'error'
                                        : ''
                                        }`}>
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>Role</label>
                                    </div>
                                    <div className="field-right" >
                                        {Loading ? <CircularProgress style={{ alignItems: 'center' }} />
                                            :
                                            <select name='roleid'
                                                defaultValue={values.roleid}
                                                value={values.roleid}
                                                onChange={(e) => {
                                                    setFieldValue('roleid', e.target.value);
                                                }}>
                                                <option value={''}>Select Role</option>                                               
                                                {dataSource != undefined &&
                                                    !!dataSource.length && dataSource.map((obj, index) => {
                                                        return <option selected={obj.ID == values.roleid ? obj.ID : obj.Name} value={obj.ID} key={index}>{obj.Name}</option>
                                                    })
                                                }
                                            </select>
                                        }
                                        {errors &&
                                            touched.roleid &&
                                            errors.roleid &&
                                            errors.roleid !==
                                            '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage
                                                        name='roleid'
                                                        render={(err) => {
                                                            return err;
                                                        }}
                                                    />
                                                </span>
                                            )}

                                    </div>
                                </div> */}

                                <div className="form_field">
                                    <div style={{ textAlign: 'left' }} className="field-left">
                                        <label>Is Active ?</label>
                                    </div>
                                    <div style={{ textAlign: 'left' }} className="field-right">
                                        <div className="custom-radio">
                                            <input type="radio" name="active" id="yes-text"
                                                defaultValue={values.active}
                                                defaultChecked={values.active != 0 ? true : false}
                                                onClick={(e) => setFieldValue('active', 1)} />
                                            <label htmlFor="yes-text">Yes</label>
                                        </div>
                                        <div className="custom-radio">
                                            <input type="radio" name="active" id="no-text"
                                                defaultValue={values.active}
                                                defaultChecked={values.active == 0 ? true : false}
                                                onClick={(e) => setFieldValue('active', 0)} />
                                            <label htmlFor="no-text">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                    <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" />
                                </div>
                            </form>
                        )
                    }}

                </Formik>

            </div>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                clearNotification={handleClearNotification.bind(this)}
                closeNotification={handleClearNotification.bind(this)}
            />
        </div>

    )
};

const validateUsersForm = yup.object().shape({
    usersFirstname: yup.string().required('First Name is required'),
    usersLastname: yup.string().required('Last Name is required'),
    usersEmailAddress: yup.string().required('Email Address is required'),
    usersPhoneNo: yup.string().required('Phone No is required'),
    // roleid: yup.string().required('Role is required'),
});

export default UserForm;