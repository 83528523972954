import React from 'react';
import TableBody from '@material-ui/core/TableBody';


const TableBodyComponent = (props) => {
   
    return (
        <TableBody className={props.className} classes={props.classes} component={'tbody'}>
            {props.children}
        </TableBody>
    )
}

export default TableBodyComponent;