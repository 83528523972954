/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './usersForm.css'
import * as APIController from '../../../../api/APIController';
import * as yup from 'yup';

import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

const AdminUsersForm = props => {
    const initialValues = {
        usersFirstname: '', usersLastname: '', usersEmailAddress: '', usersPhoneNo: '', roleid: '', userTypeid: '', active: props.history.location.state.usersActiveEdit
    }
    const [isButtonValue, setButtonValue] = React.useState(false);

    const [dataSource, setdataSource] = React.useState([]);
    const [userTypeData, setuserTypeDat] = React.useState([{ "ID": 1, "Name": "Super Admin" }, { "ID": 3, "Name": "Fulfilment" }]);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [Loading, setLoading] = React.useState(true)

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)
        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    React.useEffect(() => {
        if (props.history.location.state.usersIdEdit != 0) { wsGetUserByID(props.history.location.state.usersIdEdit) } else { wsGetRoleForDropdown(1) }

        document.getElementById('usersFirstname').focus();
    }, [props])

    const wsAddEditUser = (ID, CompanyID, FirstName, LastName, EmailAddress, PhoneNo, RoleId, IsActive, UserType) => {
        setIsLoading(true)
        APIController.AdminAddEditUser(ID, CompanyID, FirstName, LastName, EmailAddress, PhoneNo, RoleId, IsActive, UserType)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.push({ pathname: '/admin/users', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetUserByID = (ID) => {
        APIController.GetUserByID(ID)
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    initialValues.usersFirstname = response.object.data[0].FirstName
                    initialValues.usersLastname = response.object.data[0].LastName
                    initialValues.usersEmailAddress = response.object.data[0].EmailAddress
                    initialValues.usersPhoneNo = response.object.data[0].PhoneNo
                    initialValues.roleid = response.object.data[0].RoleID
                    initialValues.userTypeid = response.object.data[0].UserType
                    setdataSource(JSON.parse(response.object.data[0].Roles));
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetRoleForDropdown = (CompanyID) => {
        APIController.GetRoleForDropdown(CompanyID)
            .then((response) => {
                setLoading(false)
                if (response.error == null) {
                    setdataSource(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }


    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.usersIdEdit == '0' ? <h1> Add User </h1> : <h1>Edit User</h1>}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateUsersForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        if (!values.usersEmailAddress.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                            handleNotification('Please Enter Valid Email Address', true)
                            document.getElementById('usersEmailAddress').focus();
                        }
                        else if (values.usersPhoneNo.length < 10) {
                            handleNotification('Please Enter Valid Phone number', true)
                            document.getElementById('usersPhoneNo').focus();
                        }
                        else if (!values.usersPhoneNo.match(/^[0-9]+$/)) {
                            handleNotification("Please enter Phone No only number.", true);
                            document.getElementById('usersPhoneNo').focus();
                        }
                        else {
                            wsAddEditUser(props.history.location.state.usersIdEdit, '1', values.usersFirstname,
                                values.usersLastname, values.usersEmailAddress, values.usersPhoneNo, values.roleid, values.active, values.userTypeid)
                            if (isButtonValue === false) {
                                values.usersFirstname = "";
                                values.usersLastname = "";
                                values.usersEmailAddress = "";
                                values.usersPhoneNo = "";
                                values.active = 1;
                            }
                        }
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {

                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    {/* <div className="form_field"> */}
                                    <div
                                        className={`form_field ${errors &&
                                            touched.usersFirstname &&
                                            errors.usersFirstname &&
                                            errors.usersFirstname !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>First Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter First Name" id={'usersFirstname'} name="usersFirstname" value={values.usersFirstname} maxLength="50" />
                                            {errors &&
                                                touched.usersFirstname &&
                                                errors.usersFirstname &&
                                                errors.usersFirstname !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='usersFirstname'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    {/* <div className="form_field"> */}
                                    <div
                                        className={`form_field ${errors &&
                                            touched.usersLastname &&
                                            errors.usersLastname &&
                                            errors.usersLastname !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Last Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Last Name" name="usersLastname" value={values.usersLastname} maxLength="50" />
                                            {errors &&
                                                touched.usersLastname &&
                                                errors.usersLastname &&
                                                errors.usersLastname !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='usersLastname'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div
                                        className={`form_field ${errors &&
                                            touched.usersEmailAddress &&
                                            errors.usersEmailAddress &&
                                            errors.usersEmailAddress !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Email Address</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Email Address" id={'usersEmailAddress'} name="usersEmailAddress" value={values.usersEmailAddress} maxLength="50" />
                                            {errors &&
                                                touched.usersEmailAddress &&
                                                errors.usersEmailAddress &&
                                                errors.usersEmailAddress !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='usersEmailAddress'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.usersPhoneNo &&
                                            errors.usersPhoneNo &&
                                            errors.usersPhoneNo !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Phone No</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Phone No" id={'usersPhoneNo'} name="usersPhoneNo" value={values.usersPhoneNo} maxLength="10" />
                                            {errors &&
                                                touched.usersPhoneNo &&
                                                errors.usersPhoneNo &&
                                                errors.usersPhoneNo !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='usersPhoneNo'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </div>

                                    <div
                                        className={`form_field ${errors &&
                                            touched.roleid &&
                                            errors.roleid &&
                                            errors.roleid !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Role</label>
                                        </div>
                                        <div className="field-right" >
                                            {Loading ? <CircularProgress style={{ alignItems: 'center', }} />
                                                :
                                                <select name='roleid'
                                                    defaultValue={values.roleid}
                                                    value={values.roleid}
                                                    onChange={(e) => {
                                                        setFieldValue('roleid', e.target.value);
                                                    }}>
                                                    <option value={''}>Select Role</option>
                                                    {
                                                        dataSource.map((obj, index) => {
                                                            return <option selected={obj.ID == values.roleid ? obj.ID : obj.Name} value={obj.ID} key={index}>{obj.Name}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                            {errors &&
                                                touched.roleid &&
                                                errors.roleid &&
                                                errors.roleid !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='roleid'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}

                                        </div>
                                    </div>

                                    <div
                                        className={`form_field ${errors &&
                                            touched.userTypeid &&
                                            errors.userTypeid &&
                                            errors.userTypeid !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>User Type</label>
                                        </div>
                                        <div className="field-right" >
                                            {Loading ? <CircularProgress style={{ alignItems: 'center', }} />
                                                :
                                                <select name='userTypeid'
                                                    defaultValue={values.userTypeid}
                                                    value={values.userTypeid}
                                                    onChange={(e) => {
                                                        setFieldValue('userTypeid', e.target.value);
                                                    }}>
                                                    <option value={''}>Select User Type</option>
                                                    {
                                                        userTypeData.map((obj, index) => {
                                                            return <option selected={obj.ID == values.userTypeid ? obj.ID : obj.Name} value={obj.ID} key={index}>{obj.Name}</option>
                                                        })
                                                    }
                                                </select>
                                            }
                                            {errors &&
                                                touched.userTypeid &&
                                                errors.userTypeid &&
                                                errors.userTypeid !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage
                                                            name='userTypeid'
                                                            render={(
                                                                err
                                                            ) => {
                                                                return err;
                                                            }}
                                                        />
                                                    </span>
                                                )}

                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.usersIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>

                                </form>
                            </div>
                        )
                    }}

                </Formik>
            </section>
        </>
    )
}
const validateUsersForm = yup.object().shape({
    usersFirstname: yup.string().required('First Name is required'),
    usersLastname: yup.string().required('Last Name is required'),
    usersEmailAddress: yup.string().required('Email Address is required'),
    usersPhoneNo: yup.string().required('Phone No is required'),
    roleid: yup.string().required('Role is required'),
    userTypeid: yup.string().required('User Type is required'),
});
export default AdminUsersForm;