/*eslint-disable eqeqeq*/

import React from "react";
import Table from "../../../../shared/components/table/table";
import TableHead from "../../../../shared/components/table/tableHead";
import TableRow from "../../../../shared/components/table/tableRow";
import TableBody from "../../../../shared/components/table/tableBody";
import TableCell from "../../../../shared/components/table/tableCell";
import double_left_arrow from '../../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";
import editIcon from '../../../../assets/images/edit.svg'
import filtericon from '../../../../assets/images/ic_feather_filter.svg'

import PerfectScrollbar from "react-perfect-scrollbar";
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

const PracticesList = (props) => {

	const headerList = ['action', "Status", "Registration Date", "Ship To", "Practice Name", "Address 1", "Address 2", "City", "State", "Postcode", "Phone No", "Website", "Contact Name", "Vision Source", "VSP", "Representative", "Is CoOp Enabled"]

	const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
	let practicesList = list;
	// practicesList = list.slice(from, to)

	// const page = Math.floor(count / rowsPerPage) + 1;
	const page = rowsPerPage == 1000000 ? 1 : Math.floor(count / rowsPerPage) + 1;

	/**
	 * handleChangeInCurrentPage method is used to change page number
	 * it will check that page number does not exist last page
	 * @param {*} value 
	 */
	const handleChangeInCurrentPage = (value) => {
		if (value <= page && Number(parseInt(value))) {
			props.handleChangePage(Number(value), RegistrationDateText, PracticeNameText, ShipToText, RepresentativeText, ContactNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, PhoneNoText, VisionSourceText, VSPText, IsCoOpText, Status);
		}
	}
	const [RegistrationDateText, setRegistrationDateText] = React.useState('');
	const [PracticeNameText, setPracticeNameText] = React.useState('');
	const [ShipToText, setShipToText] = React.useState('');
	const [RepresentativeText, setRepresentativeText] = React.useState('');
	const [ContactNameText, setContactNameText] = React.useState('');
	const [WebsiteText, setWebsiteText] = React.useState('');
	const [Address1Text, setAddress1Text] = React.useState('');
	const [Address2Text, setAddress2Text] = React.useState('');
	const [CityText, setCityText] = React.useState('');
	const [StateText, setStateText] = React.useState('');
	const [ZIPCodeText, setZIPCodeText] = React.useState('');
	const [PhoneNoText, setPhoneNoText] = React.useState('');
	const [VisionSourceText, setVisionSourceText] = React.useState('');
	const [VSPText, setVSPText] = React.useState('');
	const [IsCoOpText, setIsCoOpext] = React.useState('');
	const [Status, setStatusText] = React.useState('');

	const _handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			props.applyFilter(RegistrationDateText, PracticeNameText, ShipToText, RepresentativeText, ContactNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, PhoneNoText, VisionSourceText, VSPText, IsCoOpText, Status)
		}
	}

	return (
		<>
			<h1>View Practices</h1>
			<div className="table-main-wrap" >
				<PerfectScrollbar component='div' >
					<Table>
						<TableHead>
							<TableRow>
								{headerList.map((obj, index) => {
									return <TableCell style={{ width: 10 }} className={obj === "Is active ?" ? 'center' : ''} key={index}>{obj}</TableCell>;
								})}
							</TableRow>
							<TableRow>
								<TableCell>
									<span><img src={filtericon} alt="delete" /></span>
								</TableCell>
								<TableCell>
									<select onChange={(e) => { setStatusText(e.target.value); props.applyFilter(RegistrationDateText, PracticeNameText, ShipToText, RepresentativeText, ContactNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, PhoneNoText, VisionSourceText, VSPText, IsCoOpText, e.target.value) }}>
										<option value={""}>All</option>
										<option value={"Approved"}>Approved</option>
										<option value={"Pending Approval"}>Pending Approval</option>
										<option value={"Registered"}>Registered</option>
									</select>
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Date of Registration" onKeyDown={_handleKeyDown} onChange={(e) => setRegistrationDateText(e.target.value)} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search ShipTo" onKeyDown={_handleKeyDown} onChange={(e) => setShipToText(e.target.value)} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Practice Name" onKeyDown={_handleKeyDown} onChange={(e) => setPracticeNameText(e.target.value)} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Address 1" onKeyDown={_handleKeyDown} onChange={(e) => setAddress1Text(e.target.value)} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Address 2" onKeyDown={_handleKeyDown} onChange={(e) => setAddress2Text(e.target.value)} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search City" onKeyDown={_handleKeyDown} onChange={(e) => setCityText(e.target.value)} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search State" onKeyDown={_handleKeyDown} onChange={(e) => setStateText(e.target.value)} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Postcode" onKeyDown={_handleKeyDown} onChange={(e) => setZIPCodeText(e.target.value)} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Phone Number" onKeyDown={_handleKeyDown} onChange={(e) => setPhoneNoText(e.target.value)} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Website" onKeyDown={_handleKeyDown} onChange={(e) => setWebsiteText(e.target.value)} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Contact Name" onKeyDown={_handleKeyDown} onChange={(e) => setContactNameText(e.target.value)} />
								</TableCell>
								<TableCell>
									<select onChange={(e) => { setVisionSourceText(e.target.value); props.applyFilter(RegistrationDateText, PracticeNameText, ShipToText, RepresentativeText, ContactNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, PhoneNoText, e.target.value, VSPText, IsCoOpText, Status) }}>
										<option value={""}>All</option>
										<option value={"YES"}>Yes</option>
										<option value={"NO"}>No</option>
									</select>
								</TableCell>
								<TableCell>
									<select onChange={(e) => { setVSPText(e.target.value); props.applyFilter(RegistrationDateText, PracticeNameText, ShipToText, RepresentativeText, ContactNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, PhoneNoText, VisionSourceText, e.target.value, IsCoOpText, Status) }}>
										<option value={""}>All</option>
										<option value={"YES"}>Yes</option>
										<option value={"NO"}>No</option>
									</select>
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Representative" onKeyDown={_handleKeyDown} onChange={(e) => setRepresentativeText(e.target.value)} />
								</TableCell>

								<TableCell>
									<input type="text" name="" placeholder="Search Is CoOp Enabled" disabled />
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!practicesList.length ? practicesList.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell >
											<span>
												<img src={editIcon} alt="edit" style={{ cursor: 'pointer', margin: '3px' }}
													onClick={() => {
														props.history.push({
															pathname: '/clinic/clinic',
															state: {
																PracticeEditID: obj.ID,
																IsCoOpEligibleId: obj.IsCoOpEligible,
																StatusID: obj.StatusID,
																Representative: obj.Representative,
																WebsiteEdit: obj.Website,
																RepresentativeID: obj.RepresentativeID
															}
														})
													}} />
											</span>
										</TableCell>
										<TableCell className={`${backgroundColor(obj.StatusID)}`}>
											<span>{obj.StatusName}</span>
										</TableCell>
										<TableCell>{obj.CreatedAt}</TableCell>
										<TableCell>{obj.ShipToNo}</TableCell>
										<TableCell>{obj.PracticeName}</TableCell>
										<TableCell>{obj.Address1}</TableCell>
										<TableCell>{obj.Address2}</TableCell>
										<TableCell>{obj.City}</TableCell>
										<TableCell>{obj.State}</TableCell>
										<TableCell>{obj.Postcode}</TableCell>
										<TableCell>{obj.PhoneNo}</TableCell>
										<TableCell>{obj.Website}</TableCell>
										<TableCell>{obj.ContactName}</TableCell>
										<TableCell className={obj.VisionSourceLABEL === "YES" ? 'green' : 'red'}>
											<span>{obj.VisionSourceLABEL === "YES" ? 'Yes' : 'No'}</span>
										</TableCell>
										<TableCell className={obj.VSPLABEL === "YES" ? 'green' : 'red'}>
											<span>{obj.VSPLABEL === "YES" ? 'Yes' : 'No'}</span>
										</TableCell>
										<TableCell>{obj.Representative}</TableCell>
										<TableCell className={obj.IsCoOpEligibleLabel === "YES" ? 'green' : 'red'}>
											<span>{obj.IsCoOpEligibleLabel === "YES" ? 'Yes' : 'No'}</span></TableCell>

									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headerList.length} className="not_found">
									{props.progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>
				</PerfectScrollbar>
			</div>

			<div className="table-pagination">
				<div className="pagination-info">
					<p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
				</div>
				<div className="pagination-control">
					<a href="#!" title="" className={`double-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(1)
						}}
					><img src={double_left_arrow} alt="left" /></a>
					<a href="#!" title="" className={`simple-left-arrow ${props.currentPage == 1 ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage - 1)
						}}
					><img src={left_arrow} alt="single_left" /></a>
					<span>Page</span>
					<input disabled type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
					<span>of {page}</span>
					<a href="#!" title="" className={`simple-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(currentPage + 1)
						}}
					><img src={right_arrow} alt="right" /></a>
					<a href="#!" title="" className={`double-right-arrow ${currentPage == page ? 'disabled' : ''}`}
						onClick={(e) => {
							e.preventDefault();
							handleChangeInCurrentPage(page)
						}}><img src={double_right_arrow} alt="single_right" /></a>
					<select onChange={(e) => props.handleChangeRowsPerPage(parseInt(e.target.value), RegistrationDateText, PracticeNameText, ShipToText, RepresentativeText, ContactNameText, Address1Text, Address2Text, CityText, StateText, ZIPCodeText, PhoneNoText, VisionSourceText, VSPText, IsCoOpText, Status)} defaultValue={rowsPerPage}>
						{
							rowsPerPageOptions.map((obj, index) => {
								return <option value={obj} key={index}>{obj}</option>
							})
						}
					</select>
				</div>
			</div>
		</>
	);
};

const backgroundColor = (status) => {
	let color = '';
	switch (status) {
		case 1:
			color = 'purple';
			break;
		case 2:
			color = 'green';
			break;
		case 3:
			color = 'red';
			break;
		case 'asset-uploaded':
			color = 'yellow';
			break;
		case 'raw-asset-uploaded':
			color = 'yellow';
			break;
		case 'in-review':
			color = 'red';
			break;
		default:
			color = 'blue';
			break;
	}
	return color;
};

export default PracticesList;
