/*eslint-disable eqeqeq*/
import React, { Component } from "react";
import ProductForm from '../../component/componentProduct/productForm';
import AssetForm from '../../component/componentProduct/product-asset-formTab';
import ConfigurationTab from '../../component/componentProduct/configurationTab';
import ProductChildrenForm from '../../component/componentProduct/ProductChildrenForm';
import Notification from '../../../../shared/components/notification/notification';
import * as APIController from '../../../../api/APIController.js';
import CircularProgress from "../../../../shared/components/CircularProgress/CircularProgress";

class ProductFormController extends Component {

    state = {
        step: 1,
        Message: '',
        ShowNotification: false,
        ShowAdBuilder: true,
        IsError: false,
        IsDeveloper: false,
        GetProductData: [],
        IsLoading: false
    };

    handleCancel = () => {
        this.props.history.goBack();
    };

    componentDidMount() {
        if (this.props.history.location.state.productIdEdit != 0) {
            this.wsGetProductByID(this.props.history.location.state.productIdEdit)
            this.setState({ IsDeveloper: sessionStorage.getItem("IsDeveloper") })
        }
    }

    handleNotification = (message, isError) => {
        this.setState({ Message: message, ShowNotification: true, IsError: isError })
        setTimeout(() => {
            this.handleClearNotification();
        }, 4000)
    }

    handleClearNotification = () => {
        this.setState({ Message: 'message', ShowNotification: false, IsError: false })
    }

    wsGetProductByID = (ID) => {
        this.setState({ IsLoading: true })
        APIController.GetProductByID(ID)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ GetProductData: response.object.data, ShowAdBuilder: response.object.data[0].ShowAdBuilder, step: 1, IsLoading: false })
                } else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(response.error.message, true);
                }
            })
    }
    render() {
        return (
            <div>
                <Notification
                    isError={this.state.IsError}
                    message={this.state.Message}
                    showNotification={this.state.ShowNotification}
                    clearNotification={this.handleClearNotification.bind(this)}
                    closeNotification={this.handleClearNotification.bind(this)}
                />
                <section className="content-right-main campaign-page">
                    {this.props.history.location.state.productIdEdit != 0 ? <h1>Edit Product</h1> : <h1>Add Product</h1>}
                    <div className="tab-listing">
                        <ul>
                            <li>
                                <a style={{ cursor: 'pointer' }} title="product details" className={`${this.state.step === 1 ? 'active' : ' '}`} onClick={() => this.setState({ step: 1 })}>Product Details</a>
                            </li>
                            {this.props.history.location.state.productIdEdit != 0 && !this.state.ShowAdBuilder &&
                                <li>
                                    <a style={{ cursor: 'pointer' }} title="assets" className={`${this.state.step === 2 ? 'active' : ' '}`} onClick={() => this.setState({ step: 2 })} >Assets</a>
                                </li>}
                            {this.state.IsDeveloper == "true" &&
                                <li>
                                    <a style={{ cursor: 'pointer' }} title="configuration" className={`${this.state.step === 3 ? 'active' : ' '}`} onClick={() => this.setState({ step: 3 })} >Configuration</a>
                                </li>}
                            <li>
                                <a style={{ cursor: 'pointer' }} title="Children" className={`${this.state.step === 4 ? 'active' : ' '}`} onClick={() => this.setState({ step: 4 })}>Children</a>
                            </li>
                        </ul>
                    </div>
                    {this.state.IsLoading ? <CircularProgress />
                        : <>
                            {this.state.step == 1 && <ProductForm {...this.state} history={this.props.history} GetProductData={this.state.GetProductData} />}
                            {this.state.step == 2 && <AssetForm history={this.props.history} />}
                            {this.state.step == 3 && <ConfigurationTab history={this.props.history} GetProductData={this.state.GetProductData} />}
                            {this.state.step == 4 && <ProductChildrenForm {...this.state} history={this.props.history} GetProductData={this.state.GetProductData} />}
                        </>

                    }
                </section>
            </div>
        );
    }
}

export default ProductFormController;