import React, { Component } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ModalComponent from "./modal";
import close_icon from "../../../assets/images/gray-close-ic.svg";
import SvgIcon from '@material-ui/core/SvgIcon';

import InfoOutlined from '@material-ui/icons/InfoOutlined';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

class CustomModalComponent extends Component {

	state = {
		modalOpen: false
	};
	render() {
		const { DetailsData } = this.props
		const { modalOpen } = this.state
		return (
			<div style={{ display: 'flex' }} >

				{/* {DetailsData.length ? <InfoOutlined style={{ cursor: 'pointer', height: '0.7em', width: '0.7em' }} onClick={() => { this.setState({ modalOpen: true }) }} /> : <></>} */}

				{DetailsData.length ?
					<div className="theme-option" onClick={(e) => this.setState({ modalOpen: true })}>
						<div style={{ textAlign: 'right' }}> <SvgIcon fontSize="large"><ArrowDropDownIcon /></SvgIcon>{"ARTWORK INFO"}</div>
					</div> : <></>}
				{modalOpen && <ModalComponent
					show={modalOpen}
					handleClose={() => this.setState({ modalOpen: false })}>
					<div className="popup-wrap" style={{ paddingBottom: '10px', display: 'grid', justifyContent: 'left', minWidth: '720px', overflow: 'auto' }}>
						<a title="" className="gray-close-ic">
							<img src={close_icon} alt="icon" onClick={() => this.setState({ modalOpen: false })} />
						</a>
						{DetailsData.map((obj, ind) =>
							<PerfectScrollbar>
								<label style={{ color: '#1073c6' }}>{obj.Name}</label>
								<div dangerouslySetInnerHTML={{ __html: obj.Description }} />
								<br />
							</PerfectScrollbar>
						)}
					</div>
				</ModalComponent >}
			</div>
		);
	}
}

export default CustomModalComponent;
