/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';

import uuid from 'react-uuid';
import file_upload_ic from "../../../../assets/images/file-upload-ic.svg";
import close_icon from "../../../../assets/images/close_icon.svg";
import CircularProgress from '@material-ui/core/CircularProgress';

import Editor from '../../../campaign/components/editor';

import Notification from '../../../../shared/components/notification/notification';

const CONFIG = {
    triggerChangeEvent: true,
    uploader: {
        url: "",
        insertImageAsBase64URI: false
    },
    filebrowser: {
        ajax: {
            url: "",
        },
        uploader: {
            url: "https://"
        },
    },
    style: {
        height: '250px'
    }
};

const BrandForm = props => {
    const initialValues = {
        checkIsEdit: props.history.location.state.isEdit,
        brandname: props.history.location.state.brandNameEdit,
        // branddescription: props.history.location.state.brandDescriptionEdit,
        imagename: props.history.location.state.brandImageNameEdit,
        brandlogo: props.history.location.state.brandLogoURLEdit,
        brandorderno: props.history.location.state.brandOrderNoEdit,
        active: props.history.location.state.brandActiveEdit,
        imageconvert: props.history.location.state.brandLogoURLEdit
    }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [IsLoadingData, setIsLoadingData] = React.useState(true);
    const [description, setDescription] = React.useState('');
    const [orderData, setOrderData] = React.useState([]);

    React.useEffect(() => {
        setDescription(props.history.location.state.brandDescriptionEdit)
        setIsLoadingData(false)
        var pairs = [];
        for (let i = 0; i < 100; i++) {
            pairs.push({ ID: i + 1 });
        }
        setOrderData(pairs)
    }, [props])

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsAddEditBrand = (ID, Name, Description, ImageName, ImageData, OrderNo, IsActive) => {
        setIsLoading(true)
        APIController.AddEditBrand(ID, Name, Description, ImageName, ImageData, OrderNo, IsActive)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.push({ pathname: '/brand', state: { showMessage: response.object.message } })
                    } else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    return (
        <>
            <section className="content-right-main">
                {props.history.location.state.brandIdEdit != 0 ? <h1>Edit Brand</h1> : <h1>Add Brand</h1>}
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validateProductForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        if (description == '') {
                            handleNotification("Description is required", true);
                        } else {
                            wsAddEditBrand(props.history.location.state.brandIdEdit, values.brandname, description,
                                values.imagename, values.brandlogo, values.brandorderno, values.active)
                            if (isButtonValue === false) {
                                values.brandname = "";
                                values.imageconvert = "";
                                values.brandlogo = "";
                                values.brandorderno = "";
                                values.active = true;
                                setDescription('')
                            }
                        }
                    }}
                >
                    {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        errors,
                        touched,
                    }) => {
                        return (
                            <div className="form-main">
                                <form onSubmit={handleSubmit}>
                                    <div
                                        className={`form_field ${errors &&
                                            touched.brandname &&
                                            errors.brandname &&
                                            errors.brandname !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Brand" name="brandname" value={values.brandname} maxLength="50" />
                                            {errors &&
                                                touched.brandname &&
                                                errors.brandname &&
                                                errors.brandname !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage name='brandname' render={(err) => { return err; }} />
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                    {IsLoadingData ? <CircularProgress style={{ alignItems: 'center' }} /> :
                                        <div className='form_field'>
                                            <label style={{ marginBottom: '-40px' }}>Description</label>
                                            <div className="asset-full" style={{ padding: '0px', marginBottom: '-0px' }}>
                                                <Editor content={description} setContent={setDescription} />
                                            </div>
                                        </div>
                                    }
                                    <div className={`form_field ${errors &&
                                        touched.imageconvert &&
                                        errors.imageconvert &&
                                        errors.imageconvert !== ''
                                        ? 'error'
                                        : ''
                                        }`}>
                                        <div className='field-left'>
                                            <label> Logo</label>
                                        </div>
                                        <div className='field-right'>
                                            <div className='form-file-input'>
                                                <input
                                                    className='input-file'
                                                    id='fileupload'
                                                    name='files'
                                                    type='file'
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (
                                                            file &&
                                                            (file.type.includes("svg") ||
                                                                file.type.includes("png") ||
                                                                file.type.includes("jpeg") ||
                                                                file.type.includes("jpg"))
                                                        ) {
                                                            let file1 = e.target.files[0]
                                                            file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                                            setFieldValue("imagename", values.imagename == "" ?
                                                                uuid() + "." + file.type.split('/')[1] :
                                                                values.imagename.split('.')[0] + "." + file.type.split('/')[1]);

                                                            const image2base64 = require('image-to-base64');
                                                            image2base64(file1.preview)
                                                                .then((response) => {
                                                                    setFieldValue("brandlogo", response);
                                                                })
                                                                .catch((error) => {
                                                                })

                                                            setFieldValue("imageconvert", file1.preview);
                                                        }
                                                    }}
                                                />
                                                {errors &&
                                                    touched.imageconvert &&
                                                    errors.imageconvert &&
                                                    errors.imageconvert !==
                                                    '' && (
                                                        <span className='error-msg'>
                                                            <ErrorMessage name='imageconvert' render={(err) => { return err; }} />
                                                        </span>
                                                    )}
                                                {values.imageconvert == '' ?
                                                    <label htmlFor='fileupload' className='input-file-trigger'>
                                                        <img src={file_upload_ic} alt='file-uplaod' />
                                                        {'Drag and drop or Click here to upload file'}
                                                    </label> : <div className='logo-preview'>
                                                        <img src={close_icon} alt="close-ic" className="close-ic" onClick={() => setFieldValue("imageconvert", '')} style={{ cursor: 'pointer', marginRight: '3px' }} />
                                                        <img src={values.imageconvert} alt='file-uplaod' />
                                                    </div>}
                                            </div>
                                            <p> Min image size: 650X800 px. Accepted files : .jpg, .jpeg, .png, .svg </p>
                                        </div>
                                    </div>

                                    <div
                                        className={`form_field ${errors &&
                                            touched.brandorderno &&
                                            errors.brandorderno &&
                                            errors.brandorderno !== ''
                                            ? 'error'
                                            : ''
                                            }`}>
                                        <div className="field-left">
                                            <label>Sequence #</label>
                                        </div>
                                        <div className="field-right">
                                            {/* <Field type="text" placeholder="Enter Sequence #" name="brandorderno" value={values.brandorderno} maxLength="50" /> */}
                                            <select
                                                id={'brandorderno'}
                                                name="reportType"
                                                defaultValue={values.brandorderno}
                                                value={values.brandorderno}
                                                onChange={(e) => setFieldValue('brandorderno', e.target.value)}>
                                                <option value={''}>Sequence #</option>
                                                {orderData.map((obj, index) => {
                                                    return <option selected={obj.ID == values.brandorderno ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
                                                })}
                                            </select>
                                            {errors &&
                                                touched.brandorderno &&
                                                errors.brandorderno &&
                                                errors.brandorderno !==
                                                '' && (
                                                    <span className='error-msg'>
                                                        <ErrorMessage name='brandorderno' render={(err) => { return err; }} />
                                                    </span>
                                                )}

                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-text"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.brandIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section>
        </>
    )
}
const validateProductForm = yup.object().shape({
    brandname: yup.string().required('Name is required'),
    imageconvert: yup.string().required('Logo is required'),
    brandorderno: yup.string().required('Sequence # is required'),
});

export default BrandForm;
