import React from 'react';
import TableHead from '@material-ui/core/TableHead';


const TableHeadComponent = (props) => {
    return (
        <TableHead classes={props.classes} className={props.className} component={'thead'}>
            {props.children}
        </TableHead>
    )
}

export default TableHeadComponent;