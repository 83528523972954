import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DropdownStyle from './DropdownStyle';
import zIndex from '@material-ui/core/styles/zIndex';

const CssDropdown = withStyles({
    root: {
        backgroundColor: '#f5f7fa',
        borderRadius: '8px',
        border: '1px solid transparent',
        '& label': {
            color: '#646464',
            fontFamily: 'Raleway',
            fontWeight: 'bold'
        },
        '& label.Mui-focused': {
            color: '#646464',
            borderRadius: '8px',
            fontFamily: 'Raleway',
            fontWeight: 'bold'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: '#d8dbe0',
                color: '#646464'
            },
            '&:hover fieldset': {
                borderWidth: '2px',
                borderColor: '#d8dbe0',
                borderRadius: '8px'
            },
            '&.Mui-focused fieldset': {
                borderColor: '#d8dbe0',
                borderRadius: '8px',
                color: '#646464'
            },
        },
    },
})(TextField);



const Dropdown = props => {
    const classes = DropdownStyle();
    const { isError } = props
    return (
        // <div className={isError ? classes.textFieldError : classes.textField}>
        <CssDropdown
            variant="outlined"
            fullWidth
            error={isError ? true : false}
            inputProps={{ style: { fontFamily: 'nunito', color: 'red', fontSize: 20 } }}
            {...props}>
            {props.children}
        </CssDropdown>
        // </div>
    );
};

export default Dropdown;
