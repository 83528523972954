import { makeStyles } from '@material-ui/core/styles';

const filledTextFieldUpdatedStyle = makeStyles(theme => ({
    // root: {
    //     display: 'flex',
    //     flexWrap: 'wrap',
    // },
    TextFieldStyle: {
        width: '100%',
        fontSize: 20,
        fontFamily: "Raleway, sans- serif",
        fontWeight: 'bold'
    },
    textFieldError: {
        fontSize: '16px',
        fontWeight: 400,
        boxShadow: 'none',
        color: '#646464',
        backgroundColor: ' #f5f7fa',
        lineHeight: '20px',
        // padding: '2px',
        borderRadius: '10px',
        width: '100%',
        display: ' inline - block',
        verticalAlign: 'middle',
        border: '1px solid #dc3545',
        fontFamily: "Raleway, sans- serif"
    },
    textField: {
        fontSize: '16px',
        fontWeight: 400,
        boxShadow: 'none',
        color: '#646464',
        backgroundColor: ' #f5f7fa',
        lineHeight: '20px',
        position: 'relative',
        // padding: '2px',
        borderRadius: '10px',
        width: '100%',
        display: ' inline - block',
        verticalAlign: 'middle',
        border: '1px solid #1073c6',
        fontFamily: "Raleway, sans- serif"
    },
    root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
        textTransform: 'capitalize',
    },

}));
export default filledTextFieldUpdatedStyle;