/*eslint-disable eqeqeq*/
import React, { Component } from "react";
import CampaignForm from '../components/campaign-form';
import AssetForm from '../components/asset-form';
import Notification from '../../../shared/components/notification/notification';
import * as APIController from '../../../api/APIController.js';
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

class CampaignFormController extends Component {

    state = {
        step: 1,
        isCreate: 0,
        Message: '',
        ShowNotification: false,
        CampaignID: '',
        IsError: false,
        IsLoading: this.props.history.location.state.campaignIdEdit == 0 ? false : true,
        GetCampaignData: [],
        BrandDropDown: [],
        IsBrandLoading: true,
        IsProductLoading: false,
        ProductDropDown: [],
        campaignFormValues: {
            name: '',
            brand: '',
            rawAssetUrl: '',
        },
        formValid: false,
    };


    handleCampaignForm = (values) => {
        this.setState({ formValid: true });
    };

    handleCancel = () => {
        this.props.history.goBack();
    };

    handleNotification = (message, isError) => {
        this.setState({ Message: message, ShowNotification: true, IsError: isError })
        setTimeout(() => {
            this.handleClearNotification();
        }, 4000)
    }

    handleClearNotification = () => {
        this.setState({ Message: 'message', ShowNotification: false, IsError: false })
    }
    componentDidMount() {
        if (this.props.history.location.state.campaignIdEdit != 0) {
            this.wsGetCampaignByID(this.props.history.location.state.campaignIdEdit)
            this.setState({ CampaignID: this.props.history.location.state.campaignIdEdit })
        }

    }

    ///Code Add  bY Nilesh Patel

    wsGetCampaignByID = (ID) => {
        this.setState({ IsLoading: true })
        APIController.GetCampaignByID(ID)
            .then((response) => {
                if (response.error == null) {
                    this.setState({ GetCampaignData: response.object.data, IsLoading: false })
                }
                else if (response.error.message && response.error.message.length > 0) {
                    this.handleNotification(response.error.message, true);
                }
            })
    }
    wsAddEditCampaign = (values, data) => {
        if (values.rawAssetUrl == '' && this.state.isCreate == 1) {
            this.handleNotification('Google Drive URL is required', true);
            // setIsLoading(false)
        }
        else if (data == '') {
            this.handleNotification('Products  is required', true);
            // setIsLoading(false)
        }
        else {
            APIController.AddEditCampaign(this.props.history.location.state.campaignIdEdit, values.name, values.brand, values.orderno,
                values.rawAssetUrl, data.toString(), this.state.isCreate == 0 ? '0' : '1', values.active ? '1' : '0', values.instructionsUrl)
                .then((response) => {
                    if (response.object.status == 1) {

                        if (this.state.isCreate == 2) {
                            this.props.history.push({
                                pathname: '/campaigns/add',
                                state: { campaignIdEdit: this.state.CampaignID }
                            })
                            this.setState({ step: 2 })

                        } else {
                            this.props.history.replace({
                                pathname: '/campaigns',
                                state: { showMessage: response.object.message }
                            })
                        }

                    } else {
                        this.handleNotification(response.object.message, true);
                    }
                })
        }
    }
    ClickNextStep = (values, products) => {
        const data = JSON.parse(products).filter(function (temp) {
            return temp.IsSelected == 1
        }).map((data) => { return data.ID });

        APIController.AddEditCampaign(this.props.history.location.state.campaignIdEdit, values.Name, values.BrandID, values.OrderNo, values.RawAssetURL,
            data.toString(), this.state.isCreate == 0 ? '0' : '1', values.IsLive, values.InstructionsURL)
            .then((response) => {
                if (response.object.status == 1) {
                    this.props.history.push({
                        pathname: '/campaigns/add',
                        state: { campaignIdEdit: this.state.CampaignID }
                    })
                    this.setState({ step: 2 })
                } else {
                    this.handleNotification(response.object.message, true);
                }
            })

    }
    //-------------------------------------------------------------------------------
    render() {
        return (

            <div>
                <Notification
                    isError={this.state.IsError}
                    message={this.state.Message}
                    showNotification={this.state.ShowNotification}
                    clearNotification={this.handleClearNotification.bind(this)}
                    closeNotification={this.handleClearNotification.bind(this)}
                />
                <section className="content-right-main campaign-page">
                    {this.props.history.location.state.campaignIdEdit == 0 ? <h1>Add Campaign</h1> : <h1>Edit Campaign</h1>}
                    <div className="tab-listing">
                        <ul>
                            <li>
                                <a style={{ cursor: 'pointer' }} title="Campaign" className={`${this.state.step === 1 ? 'active' : ' '}`} onClick={() => this.setState({ step: 1 })}>Campaign</a>
                            </li>
                            {this.props.history.location.state.campaignIdEdit !== 0 && <li>
                                <a style={{ cursor: 'pointer' }} title="upload assets" className={`${this.state.step === 2 ? 'active' : ' '}`} onClick={() => this.ClickNextStep(this.state.GetCampaignData[0], this.state.GetCampaignData[0].Products)} >upload assets</a>
                            </li>}
                        </ul>
                    </div>
                    {
                        this.state.IsLoading ? <CircularProgress />
                            :
                            this.state.step == 1 ?
                                <CampaignForm
                                    {...this.state}
                                    AddEditCampaign={this.wsAddEditCampaign.bind(this)}
                                    isCreate={(val) => this.setState({ isCreate: val })}
                                    GetCampaignData={this.state.GetCampaignData}
                                    history={this.props.history}
                                    handleSubmit={this.handleCampaignForm}
                                    isFormValid={this.state.formValid}
                                    toUploadAsset={() => this.setState({ step: 2 })}
                                /> :
                                <AssetForm />
                    }
                </section>
            </div>
        );
    }
}

export default CampaignFormController;