/*eslint-disable eqeqeq*/
import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik'
// import './productForm.css'
import * as APIController from '../../../../api/APIController.js';
import * as yup from 'yup';
import uuid from 'react-uuid';
import file_upload_ic from "../../../../assets/images/file-upload-ic.svg";
import close_icon from "../../../../assets/images/close_icon.svg";

import Editor from '../../../campaign/components/editor';

import Notification from '../../../../shared/components/notification/notification';
import CircularProgress from '@material-ui/core/CircularProgress';

import PerfectScrollbar from "react-perfect-scrollbar";
import deleteIcon from "../../../../assets/images/delete.svg";
import editIcon from "../../../../assets/images/edit.svg";
import previewIcon from "../../../../assets/images/blue-preview.svg";
import { TrendingUpRounded } from '@material-ui/icons';

import ModalComponent from '../../../../shared/components/modal/modal';


const ProductForm = props => {
    const initialValues = {
        productname: "", producttagline: "", productlogoname: "", productlogo: "", producthovername: "", producthoverlogo: "",
        displayimagename: "", displayimage: "", productorderno: "",
        active: props.history.location.state.productActiveEdit, imagetemp: "", imagehovertemp: "", displayimagetemp: "", cooppercentage: "", users: [], allUsers: false,
        productimageID: '0', productimageName: "", productimageData: "", orderno: "", brand: "", isparent: props.history.location.state.productIsParentEdit
    }
    const [isButtonValue, setButtonValue] = React.useState(false);
    const [IsLoading, setIsLoading] = React.useState(false);
    const [IsLoadingData, setIsLoadingData] = React.useState(true);
    const [LoadingAssets, setLoadingAssets] = React.useState(false);
    const [sourceAssetTypes, setsourceAssetTypes] = React.useState([]);
    const [description, setDescription] = React.useState('');
    const [inclusions, setInclusions] = React.useState('');
    const [orderData, setOrderData] = React.useState([]);

    const [isProgress, setProgress] = React.useState(true);
    const [BrandsData, setBrandsData] = React.useState([]);
    const [ProductImages, setProductImages] = React.useState([]);
    const [ModalImage, setModalImage] = React.useState(null);
    const [baseImageData, setbaseImageData] = React.useState('');

    React.useEffect(() => {
        if (props.history.location.state.productIdEdit != 0 && props.GetProductData.length) {
            wsGetProductByID(props)
            wsGetProductImages(props.history.location.state.productIdEdit)
        } else {
            wsGetAssetTypes()
            setIsLoadingData(false)
        }
        var orderSequenceNo = [];
        for (let i = 0; i < 100; i++) {
            orderSequenceNo.push({ ID: i + 1 });
        }
        setOrderData(orderSequenceNo)

    }, [props])

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleNotification = (message, isError) => {
        setMessage(message)
        setShowNotification(true)
        setIsError(isError)

        setTimeout(() => {
            handleClearNotification();
        }, 4000)
    }

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }

    const wsGetProductByID = (props) => {
        initialValues.name = props.GetProductData[0].Name
        setDescription(props.GetProductData[0].Description)
        setInclusions(props.GetProductData[0].Inclusions)
        initialValues.productname = props.GetProductData[0].Name
        initialValues.producttagline = props.GetProductData[0].TagLine == null ? "" : props.GetProductData[0].TagLine
        initialValues.productlogo = props.GetProductData[0].ImageURL
        initialValues.imagetemp = props.GetProductData[0].ImageURL
        initialValues.productlogoname = props.GetProductData[0].ImageName
        initialValues.producthoverlogo = props.GetProductData[0].HoverImageURL
        initialValues.displayimage = props.GetProductData[0].DisplayImageURL
        initialValues.imagehovertemp = props.GetProductData[0].HoverImageURL
        initialValues.displayimagetemp = props.GetProductData[0].DisplayImageURL
        initialValues.producthovername = props.GetProductData[0].HoverImageName
        initialValues.displayimagename = props.GetProductData[0].DisplayImageName
        initialValues.cooppercentage = props.GetProductData[0].CoOpPercentage
        initialValues.productorderno = props.GetProductData[0].OrderNo
        setsourceAssetTypes(JSON.parse(props.GetProductData[0].ProductAssetTypes))

        // setsourceProducts(JSON.parse(props.GetProductData[0].Parents).map((item, index) =>
        //     (props.GetProductData[0].ParentIDs || '').indexOf(item.ID) > -1 ? { ...item, IsSelected: 1 } : item
        // ))

        wsGetBrandForDropdown()
        setIsLoadingData(false)
    }

    const wsGetAssetTypes = () => {
        setLoadingAssets(true);
        APIController.GetAssetTypes()
            .then((response) => {
                if (response.error == null) {
                    setsourceAssetTypes(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true)
                }
                setLoadingAssets(false);
            })
    }

    const wsAddEditProduct = (ID, Name, TagLine, Description, ImageName, ImageData, HoverImageName, HoverImageData, OrderNo, IsActive, Inclusions, CoOpPercentage, ProductAssetTypes, DisplayImageName, DisplayImageData, IsParent) => {
        setIsLoading(true)
        APIController.AddEditProduct(ID, Name, TagLine, Description, ImageName, ImageData, HoverImageName, HoverImageData, OrderNo, IsActive, Inclusions, CoOpPercentage, ProductAssetTypes, DisplayImageName, DisplayImageData, IsParent)
            .then((response) => {
                setIsLoading(false)
                if (response.error == null) {
                    handleNotification(response.object.message)
                    if (isButtonValue === true && response.object.status == 1) {
                        props.history.push({ pathname: '/product', state: { showMessage: response.object.message } })
                    }
                    else if (response.object.status == 1) {
                        handleNotification(response.object.message);
                    } else {
                        handleNotification(response.object.message, true);
                    }
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetProductImages = (ID) => {
        setProgress(true)
        APIController.GetProductImages(ID)
            .then((response) => {
                if (response.error == null) {
                    setProductImages(response.object.data)
                } else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
                setProgress(false)
            })
    }
    const wsAddEditProductImage = (ID, SequenceNo, ProductID, BrandID, ImageName, ImageData) => {
        APIController.AddEditProductImage(ID, SequenceNo, ProductID, BrandID, ImageName, ImageData)
            .then((response) => {
                if (response.error == null) {
                    setProductImages(response.object.data)
                    wsGetProductImages(ProductID)
                    initialValues.productimageName = ""
                    initialValues.productimageData = ""
                    initialValues.orderno = ""
                    initialValues.brand = ""
                    handleNotification(response.object.message);
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }
    const wsDeleteProductImage = (ID, ProductID) => {
        APIController.DeleteProductImage(ID)
            .then((response) => {
                if (response.error == null) {
                    wsGetProductImages(ProductID)
                    // setInclusions(response.object.data[0].Inclusions)
                    // initialValues.productorderno = response.object.data[0].OrderNo
                    // setsourceAssetTypes(JSON.parse(response.object.data[0].ProductAssetTypes))
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    const wsGetBrandForDropdown = () => {
        APIController.GetBrandForDropdown()
            .then((response) => {
                // setLoading(false)
                if (response.error == null) {
                    setBrandsData(response.object.data)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    handleNotification(response.error.message, true);
                }
            })
    }

    return (
        <>
            <section className="tab-content asset-tab-wrapper">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateProductForm}
                    validateOnMount={false}
                    onSubmit={(values) => {
                        const data = sourceAssetTypes.filter(function (temp) {
                            return temp.IsSelected == 1
                        }).map((data) => { return data.ID });

                        if (description == '') {
                            handleNotification("Description is required", true);
                        } else if (inclusions == '') {
                            handleNotification("Inclusions is required", true);
                        } else {

                            wsAddEditProduct(props.history.location.state.productIdEdit, values.productname, values.producttagline, description,
                                values.productlogoname, values.productlogo,
                                values.producthovername, values.producthoverlogo,
                                values.productorderno, values.active, inclusions,
                                values.cooppercentage,
                                data.toString(),
                                values.displayimagename, values.displayimage,
                                values.isparent)


                            if (isButtonValue === false) {
                                values.productname = "";
                                values.producttagline = "";
                                values.imagetemp = "";
                                values.imagehovertemp = "";
                                values.displayimagetemp = "";
                                values.productorderno = "";
                                values.cooppercentage = "";
                                values.active = 1;
                            }
                        }

                    }}
                >
                    {({ values, handleSubmit, setFieldValue, errors, touched }) => {
                        return (
                            <div className="form-main" style={{ padding: '0px', boxShadow: '0px 0px 0px' }}>
                                <form onSubmit={handleSubmit}>

                                    <div className={`form_field ${errors && touched.productname && errors.productname && errors.productname !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Name</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Product" name="productname" value={values.productname} maxLength="50" />
                                            {errors && touched.productname && errors.productname && errors.productname !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='productname' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Parent ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="isparent" id="yes-text"
                                                    defaultValue={values.isparent}
                                                    defaultChecked={values.isparent != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('isparent', 1)} />
                                                <label htmlFor="yes-text">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="isparent" id="no-text"
                                                    defaultValue={values.isparent}
                                                    defaultChecked={values.isparent == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('isparent', 0)} />
                                                <label htmlFor="no-text">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`form_field ${errors && touched.producttagline && errors.producttagline && errors.producttagline !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Tag Line</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" placeholder="Enter Tag Line" name="producttagline" value={values.producttagline} maxLength="100" />
                                            {errors && touched.producttagline && errors.producttagline && errors.producttagline !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='producttagline' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.productorderno && errors.productorderno && errors.productorderno !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Sequence #</label>
                                        </div>
                                        <div className="field-right">
                                            <select
                                                id={'productorderno'}
                                                name="reportType"
                                                defaultValue={values.productorderno}
                                                value={values.productorderno}
                                                onChange={(e) => setFieldValue('productorderno', e.target.value)}>
                                                <option value={''}>Sequence #</option>
                                                {orderData.map((obj, index) => {
                                                    return <option selected={obj.ID == values.productorderno ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID}</option>
                                                })}
                                            </select>
                                            {errors && touched.productorderno && errors.productorderno && errors.productorderno !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='productorderno' render={(err) => { return err; }} />
                                                </span>)}
                                        </div>
                                    </div>
                                    <div className={`form_field ${errors && touched.cooppercentage && errors.cooppercentage && errors.cooppercentage !== '' ? 'error' : ''}`}>
                                        <div className="field-left">
                                            <label>Co-Op %</label>
                                        </div>
                                        <div className="field-right">
                                            <Field type="text" pattern="[0-9]*" placeholder="Enter Co-Op %" name="cooppercentage" value={values.cooppercentage} maxLength="2" />
                                            {errors && touched.cooppercentage && errors.cooppercentage && errors.cooppercentage !== '' && (
                                                <span className='error-msg'>
                                                    <ErrorMessage name='cooppercentage' render={(err) => { return err; }} />
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    {IsLoadingData ? <CircularProgress style={{ alignItems: 'center' }} /> :
                                        <>
                                            <div className='form_field'>
                                                <label style={{ marginBottom: '-40px' }}>Description</label>
                                                <div className="asset-full" style={{ padding: '0px', marginBottom: '-0px' }}>
                                                    <Editor content={description} setContent={setDescription} />
                                                </div>
                                            </div>
                                            <div className='form_field'>
                                                <label style={{ marginBottom: '-40px' }}> Inclusion</label>
                                                <div className="asset-full" style={{ padding: '0px', marginBottom: '-0px' }}>
                                                    <Editor content={inclusions} setContent={setInclusions} />
                                                </div>
                                            </div>

                                            <div className='form_field' style={{ flexWrap: 'nowrap' }}>
                                                <div className={`form_field ${errors && touched.imagetemp && errors.imagetemp && errors.imagetemp !== '' ? 'error' : ''}`}>

                                                    <div className='field-right-image' style={{ paddingRight: '30px' }}>
                                                        <label>Logo</label>
                                                        <div className='form-file-input'>
                                                            <input
                                                                className='input-file'
                                                                id='fileupload'
                                                                name='files'
                                                                type='file'
                                                                onChange={(e) => {
                                                                    const file = e.target.files[0];
                                                                    if (file &&
                                                                        (file.type.includes("svg") ||
                                                                            file.type.includes("png") ||
                                                                            file.type.includes("jpeg") ||
                                                                            file.type.includes("jpg"))) {
                                                                        let file1 = e.target.files[0]
                                                                        file1['preview'] = URL.createObjectURL(e.target.files[0]);

                                                                        setFieldValue("productlogoname", uuid() + "." + file.type.split('/')[1]);

                                                                        const image2base64 = require('image-to-base64');
                                                                        image2base64(file1.preview)
                                                                            .then((response) => {
                                                                                setFieldValue("productlogo", response);
                                                                            })
                                                                            .catch((error) => { })
                                                                        setFieldValue("imagetemp", file1.preview);
                                                                    }
                                                                }}
                                                            />
                                                            {errors && touched.imagetemp && errors.imagetemp && errors.imagetemp !== '' && (
                                                                <span className='error-msg'>
                                                                    <ErrorMessage name='imagetemp' render={(err) => { return err; }} />
                                                                </span>)}
                                                            {values.imagetemp == '' ?
                                                                <label htmlFor='fileupload' className='input-file-trigger'>
                                                                    <img src={file_upload_ic} alt='file-uplaod' />
                                                                    {'Drag and drop or Click here to upload file'}
                                                                </label> : <div className='logo-preview'>
                                                                    <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => setFieldValue("imagetemp", '')} /></a>
                                                                    <img src={values.imagetemp} style={{ maxHeight: '300px', maxWidth: '300px' }} />
                                                                </div>
                                                            }
                                                        </div>
                                                        <p style={{ fontSize: '15px' }}> Max image size: 110X100 px. Accepted files : .jpg, .jpeg, .png, .svg </p>
                                                    </div>
                                                </div>

                                                <div className={`form_field ${errors && touched.imagehovertemp && errors.imagehovertemp && errors.imagehovertemp !== '' ? 'error' : ''}`}>
                                                    <div className='field-right-image' style={{ paddingLeft: '30px' }}>
                                                        <label>Hover Logo</label>
                                                        <div className='form-file-input'>
                                                            <input
                                                                className='input-file'
                                                                id='fileupload'
                                                                name='files'
                                                                type='file'
                                                                onChange={(e) => {
                                                                    const file = e.target.files[0];
                                                                    if (file &&
                                                                        (file.type.includes("svg") ||
                                                                            file.type.includes("png") ||
                                                                            file.type.includes("jpeg") ||
                                                                            file.type.includes("jpg"))
                                                                    ) {
                                                                        let file1 = e.target.files[0]
                                                                        file1['preview'] = URL.createObjectURL(e.target.files[0]);
                                                                        setFieldValue("producthovername", uuid() + "." + file.type.split('/')[1]);

                                                                        const image2base64 = require('image-to-base64');
                                                                        image2base64(file1.preview)
                                                                            .then((response) => { setFieldValue("producthoverlogo", response); })
                                                                            .catch((error) => { })

                                                                        setFieldValue("imagehovertemp", file1.preview);
                                                                    }
                                                                }}
                                                            />
                                                            {errors && touched.imagehovertemp && errors.imagehovertemp && errors.imagehovertemp !== '' && (<span className='error-msg'>
                                                                <ErrorMessage name='imagehovertemp' render={(err) => { return err; }} />
                                                            </span>)}
                                                            {values.imagehovertemp == '' ?
                                                                <label htmlFor='fileupload' className='input-file-trigger'>
                                                                    <img src={file_upload_ic} alt='file-uplaod' />
                                                                    {'Drag and drop or Click here to upload file'}
                                                                </label> : <div className='logo-preview'>
                                                                    <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => setFieldValue("imagehovertemp", '')} /></a>
                                                                    <img src={values.imagehovertemp} style={{ maxHeight: '300px', maxWidth: '300px' }} />
                                                                </div>}
                                                        </div>
                                                        <p style={{ fontSize: '15px' }}> Max image size: 110X100 px. Accepted files : .jpg, .jpeg, .png, .svg </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                    {/* <div className={`form_field ${errors && touched.displayimagetemp && errors.displayimagetemp && errors.displayimagetemp !== '' ? 'error' : ''}`}>
                                        <div className='field-left'>
                                            <label>Display Image</label>
                                        </div>
                                        <div className='field-right'>
                                            <div className='form-file-input'>
                                                <input
                                                    className='input-file'
                                                    id='fileupload'
                                                    name='files'
                                                    type='file'
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file &&
                                                            (file.type.includes("svg") ||
                                                                file.type.includes("png") ||
                                                                file.type.includes("jpeg") ||
                                                                file.type.includes("jpg"))
                                                        ) {
                                                            let file1 = e.target.files[0]
                                                            file1['preview'] = URL.createObjectURL(e.target.files[0]);
                                                            setFieldValue("displayimagename", uuid() + "." + file.type.split('/')[1]);

                                                            const image2base64 = require('image-to-base64');
                                                            image2base64(file1.preview)
                                                                .then((response) => { setFieldValue("displayimage", response); })
                                                                .catch((error) => { })

                                                            setFieldValue("displayimagetemp", file1.preview);
                                                        }
                                                    }}
                                                />
                                                {errors && touched.displayimagetemp && errors.displayimagetemp && errors.displayimagetemp !== '' && (<span className='error-msg'>
                                                    <ErrorMessage name='displayimagetemp' render={(err) => { return err; }} />
                                                </span>)}
                                                {values.displayimagetemp == '' ?
                                                    <label htmlFor='fileupload' className='input-file-trigger'>
                                                        <img src={file_upload_ic} alt='file-uplaod' />
                                                        {'Drag and drop or Click here to upload file'}
                                                    </label> : <div className='logo-preview'>
                                                        <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => setFieldValue("displayimagetemp", '')} /></a>
                                                        <img src={values.displayimagetemp} />
                                                    </div>}
                                            </div>
                                            <p> Min image size: 650X800 px. Accepted files : .jpg, .jpeg, .png, .svg </p>
                                        </div>
                                    </div> */}

                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Asset Types</label>
                                        </div>
                                        {LoadingAssets ? <CircularProgress style={{ alignItems: 'center' }} />
                                            : <div className="field-right">
                                                <div className='info-head'>
                                                    <div className='custom-checkbox'>
                                                        <label htmlFor='allUsers'>Asset Types</label>
                                                    </div>
                                                </div>
                                                <div className='info-body' style={{ display: 'table', width: '100%' }}>
                                                    {sourceAssetTypes.map((obj, index) => {
                                                        return (
                                                            <div className='info-body-row' key={index} style={{ float: 'left', width: '50%' }}>
                                                                <div className='custom-checkbox'>
                                                                    <Field
                                                                        type='checkbox'
                                                                        name={`user-${obj.ID}`}
                                                                        checked={obj.IsSelected}
                                                                        id={`user-${obj.ID}`}
                                                                        onChange={(e) => {
                                                                            setsourceAssetTypes(sourceAssetTypes.map((item, idx) => item.ID !== obj.ID ? item : { ...item, IsSelected: e.target.checked }))
                                                                        }}
                                                                    />
                                                                    <label htmlFor={`user-${obj.ID}`}> {obj.Name}</label>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        }
                                    </div>
                                    <div className="form_field">
                                        <div className="field-left">
                                            <label>Is Active ?</label>
                                        </div>
                                        <div className="field-right">
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="yes-txt"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active != 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 1)} />
                                                <label htmlFor="yes-txt">Yes</label>
                                            </div>
                                            <div className="custom-radio">
                                                <input type="radio" name="active" id="no-txt"
                                                    defaultValue={values.active}
                                                    defaultChecked={values.active == 0 ? true : false}
                                                    onClick={(e) => setFieldValue('active', 0)} />
                                                <label htmlFor="no-txt">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    {props.history.location.state.productIdEdit != 0 &&
                                        <div className='form_field'>
                                            <label style={{ marginBottom: '-10px' }}>Display Images</label>
                                            <div className="asset-tab-content">
                                                <div className="asset-tab-left">

                                                    <div className="tab_field">
                                                        <select
                                                            id={'brand'}
                                                            name='brand'
                                                            defaultValue={values.brand}
                                                            value={values.brand}
                                                            onChange={(e) => { setFieldValue('brand', e.target.value); }}>
                                                            <option value={''}>Select Brand</option>
                                                            {BrandsData.map((obj, index) => {
                                                                return <option selected={obj.ID == values.brand ? obj.ID : obj.Name} value={obj.ID} key={index}>{obj.Name}</option>
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="tab_field">
                                                        <select
                                                            id={'orderno'}
                                                            name="reportType"
                                                            defaultValue={values.orderno}
                                                            value={values.orderno}
                                                            onChange={(e) => setFieldValue('orderno', e.target.value)}>
                                                            <option value={''}>Sequence #</option>
                                                            {orderData.map((obj, index) => {
                                                                return <option selected={obj.ID == values.orderno ? obj.ID : obj.ID} value={obj.ID} key={index}>{obj.ID} </option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form_field">
                                                        <div className='form-file-input'>
                                                            <input className='input-file' id='fileupload' name='files' type='file' onChange={(e) => {
                                                                const file = e.target.files[0];
                                                                if (file && (file.type.includes("svg") || file.type.includes("png") || file.type.includes("jpeg") || file.type.includes("jpg"))) {
                                                                    let file1 = e.target.files[0]
                                                                    file1['preview'] = URL.createObjectURL(e.target.files[0]);
                                                                    setFieldValue("productimageName", uuid() + "." + file.type.split('/')[1]);
                                                                    const image2base64 = require('image-to-base64');
                                                                    image2base64(file1.preview)
                                                                        .then((response) => {
                                                                            setbaseImageData(response)
                                                                        })
                                                                        .catch((error) => {
                                                                        })

                                                                    setFieldValue('productimageData', file1.preview);
                                                                }
                                                            }} />
                                                            {values.productimageData == '' ?
                                                                <label htmlFor='fileupload' className='input-file-trigger'>Drag and drop your file here</label> :
                                                                <div className='logo-preview'>
                                                                    <a title="" className="close-ic"><img src={close_icon} alt="close-ic" onClick={() => { setFieldValue('productimageData', ''); setbaseImageData('') }} /></a>
                                                                    <img src={values.productimageData} style={{ height: '170px', width: '230px' }} alt='' />
                                                                </div>}
                                                        </div>
                                                        <p> Min image size: 650X800 px. Accepted files : .jpg, .jpeg, .png, .svg </p>
                                                    </div>
                                                </div>
                                                <div className="asset-tab-right">
                                                    <div className="asset-table-head">
                                                        <div style={{ width: '16%', padding: '15px' }} className="asset-table-width-17"><h5>Action</h5></div>
                                                        {/* <div style={{ width: '60.67%', padding: '15px' }} className="asset-table-width-1"><h5>Image Name</h5></div> */}
                                                        <div style={{ width: ' 54.33%', padding: '15px' }} className="asset-table-width-22"><h5>Brand</h5></div>
                                                        <div style={{ width: ' 29.33%', padding: '15px' }} className="asset-table-width-22"><h5>Sequence No</h5></div>
                                                    </div>
                                                    {<PerfectScrollbar style={{ maxHeight: '400px' }}>
                                                        {isProgress ? <div style={{ textAlign: 'center', marginTop: "2%" }}>
                                                            <CircularProgress style={{ color: '#0a5c7f', alignSelf: 'center' }} />
                                                        </div>
                                                            : !!ProductImages.length ? ProductImages.map((obj, index) => {
                                                                return (
                                                                    <div className="asset-table-body" key={index}>
                                                                        <div style={{ width: '16%' }} className="asset-table-width-17">
                                                                            <a style={{ cursor: 'pointer' }} title="" className="small-ic" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setFieldValue('productimageID', obj.ID);
                                                                                setFieldValue('productimageName', obj.ImageName);
                                                                                setFieldValue('productimageData', obj.ImageURL);
                                                                                setFieldValue('orderno', obj.SequenceNo);
                                                                                setFieldValue('brand', obj.BrandID);
                                                                                setbaseImageData(obj.ImageURL)
                                                                            }}>
                                                                                <img src={editIcon} alt="icon" />
                                                                            </a>
                                                                            <a style={{ cursor: 'pointer' }} title="" className="small-ic" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                wsDeleteProductImage(obj.ID, props.history.location.state.productIdEdit)
                                                                            }}>
                                                                                <img src={deleteIcon} alt="icon" />
                                                                            </a>
                                                                            <a style={{ cursor: 'pointer' }} title="" onClick={() =>
                                                                                setModalImage({
                                                                                    isModalOpen: true,
                                                                                    content: { image: obj.ImageURL },
                                                                                })
                                                                            }>
                                                                                <img src={previewIcon} alt="icon" />
                                                                            </a>
                                                                        </div>
                                                                        <div style={{ width: '55.67%', textAlign: 'left' }} className="asset-table-width-1"><p>{obj.BrandName}</p></div>
                                                                        <div style={{ width: '20.33%', textAlign: 'center' }} className="asset-table-width-22"><p>{obj.SequenceNo}</p></div>
                                                                    </div>
                                                                );
                                                            }) :
                                                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: "2%" }}>
                                                                    <label>Not Currently Available</label>
                                                                </div>}
                                                    </PerfectScrollbar>}
                                                </div>
                                                <div className={IsLoading ? "tab-submit-btn button-field disable-btn" : "tab-submit-btn button-field"}>
                                                    <input type="button" value="Save" name="save" onClick={() => {
                                                        wsAddEditProductImage(values.productimageID, values.orderno, props.history.location.state.productIdEdit, values.brand, values.productimageName, baseImageData)
                                                    }} />

                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <Notification
                                        isError={isError}
                                        message={message}
                                        showNotification={showNotification}
                                        clearNotification={handleClearNotification.bind(this)}
                                        closeNotification={handleClearNotification.bind(this)}
                                    />

                                    {ModalImage && ModalImage.isModalOpen && (
                                        <ModalComponent
                                            show={ModalImage.isModalOpen}
                                            handleClose={() => setModalImage(null)}>
                                            <div className='popup-wrap'>
                                                <img src={ModalImage.content.image} alt='place' />
                                                <img src={close_icon} alt='icon' className='gray-close-ic' onClick={() => setModalImage(null)} style={{ cursor: 'pointer' }} />
                                            </div>
                                        </ModalComponent>
                                    )}
                                    <div className="form-submit">
                                        <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                            <input type="submit" value={IsLoading ? "Saving..." : "save"} name="save" onClick={() => setButtonValue(true)} />
                                        </div>
                                        {props.history.location.state.productIdEdit == 0 ?
                                            <div className={IsLoading ? "button-field disable-btn" : "button-field"}>
                                                <input type="submit" value={IsLoading ? "Saving..." : "save & add new"} name="save & add new" onClick={() => setButtonValue(false)} />
                                            </div> : null}
                                        <div className="button-field ">
                                            <input type="button" value="close" name="close" onClick={() => props.history.goBack()} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )
                    }}
                </Formik>
            </section >
        </>
    )
}
const validateProductForm = yup.object().shape({
    productname: yup.string().required('Name is required'),
    producttagline: yup.string().required('Tag Line is required'),
    // imagetemp: yup.string().required('Logo is required'),
    // imagehovertemp: yup.string().required('Hover Logo is required'),
    // displayimagetemp: yup.string().required('Display Image is required'),
    productorderno: yup.string().required('Sequence # is required'),
    cooppercentage: yup.string().required('Co-Op% is required'),
});
export default ProductForm;