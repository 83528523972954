export const addressData = [
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "India",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{
		addressName: "24$Music & Dvds, Bookstores",
		address1: "144 W 66th St",
		address2: "Upper West Side",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "www.xwy.com",
		instagramUrl: "www.xwy.com",
	},
	{

		addressName: "Marietta Eye Clinic",
		address1: "895 Canton Road NE",
		address2: "Marietta, GA 30060",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "https://www.facebook.com/MariettaEyeClinic",
		instagramUrl: "https://www.instagram.com/explore/locations/19772479/marietta-eye-clinic",
	},
	{
		addressName: "Marietta Eye Clinic",
		address1: "2453 Powder Springs Road",
		address2: "Marietta, GA 30064",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "https://www.facebook.com/MariettaEyeClinic",
		instagramUrl: "https://www.instagram.com/explore/locations/19772479/marietta-eye-clinic",
	},
	{
		addressName: "Marietta Eye Clinic",
		address1: "100 Old Ballground Hwy",
		address2: "Suite A, Canton, GA 30114",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "https://www.facebook.com/MariettaEyeClinic",
		instagramUrl: "https://www.instagram.com/explore/locations/19772479/marietta-eye-clinic",
	},
	{	
		addressName: "Marietta Eye Clinic",
		address1: "3939 Roswell Road",
		address2: "Suite 100, Marietta, GA 30062",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "https://www.facebook.com/MariettaEyeClinic",
		instagramUrl: "https://www.instagram.com/explore/locations/19772479/marietta-eye-clinic",
	},
	{	
		addressName: "Marietta Eye Clinic",
		address1: "8841 Hospital Drive",
		address2: "Douglasville, GA 30134",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "https://www.facebook.com/MariettaEyeClinic",
		instagramUrl: "https://www.instagram.com/explore/locations/19772479/marietta-eye-clinic",
	},
	{
		addressName: "Marietta Eye Clinic",
		address1: "4450 Calibre Xing",
		address2: "Suite 1104, Acworth, GA 30101",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "https://www.facebook.com/MariettaEyeClinic",
		instagramUrl: "https://www.instagram.com/explore/locations/19772479/marietta-eye-clinic",
	},
	{
		addressName: "Marietta Eye Clinic",
		address1: "47 Highland Pavilion Court",
		address2: "Hiram, GA 30141",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "https://www.facebook.com/MariettaEyeClinic",
		instagramUrl: "https://www.instagram.com/explore/locations/19772479/marietta-eye-clinic",
	},
	{ 
		addressName: "Marietta Eye Clinic",
		address1: "2990 Eagle Drive, Suites 110 & 111",
		address2: "Woodstock, GA 30189",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "https://www.facebook.com/MariettaEyeClinic",
		instagramUrl: "https://www.instagram.com/explore/locations/19772479/marietta-eye-clinic",
	},
	{
		addressName: "Marietta Eye Clinic",
		address1: "520 Windy Hill Road",
		address2: "Suite 206 Marietta, GA 30067",
		postCode: "(212) 799-5000",
		country: "USA",
		state: "New York",
		city: "Beacon",
		email: "xyz@gmail.com",
		businessPhone: "9999999999",
		businessEmail: "true",
		website: "www.xwy.com",
		facebookUrl: "https://www.facebook.com/MariettaEyeClinic",
		instagramUrl: "https://www.instagram.com/explore/locations/19772479/marietta-eye-clinic",
	},
];
