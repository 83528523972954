/*eslint-disable eqeqeq*/
import React from "react";
import Table from "../../../shared/components/table/table";
import TableHead from "../../../shared/components/table/tableHead";
import TableRow from "../../../shared/components/table/tableRow";
import TableBody from "../../../shared/components/table/tableBody";
import TableCell from "../../../shared/components/table/tableCell";
import filtericon from "../../../assets/images/ic_feather_filter.svg";
import editIcon from "../../../assets/images/edit.svg";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import EditPractice from "../component/editPractice";
import ModalComponent from "../../../shared/components/modal/modal";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import * as APIController from '../../../api/APIController.js';

const AddressListComponent = (props) => {
	// const list = props.list;
	const [list, setlist] = React.useState([]);
	const [progress, setProgress] = React.useState(true);

	const [isModalOpen, setisModalOpen] = React.useState(false);
	const [EditData, setEditData] = React.useState('');
	const [ModalTitle, setModalTitle] = React.useState('');

	const headings = ["action", "Practice Name", "Ship To", " Representative", " Contact Name", " Address 1", "Address 2",
		"City", "State", "Postcode", "Phone No", "Email Address ", "Vision Source", "VSP", "Website"]

	React.useEffect(() => {
		wsGetPractices(props.PracticeID);
	}, [props])

	const wsGetPractices = (ID) => {
		setProgress(true)
		APIController.GetPracticeByCompanyID(ID)
			.then((response) => {
				if (response.error == null) {
					setlist(response.object.data)
					setProgress(false)
				}
				else if (response.error.message && response.error.message.length > 0) {
					// props.handleNotification(response.error.message);
				}
			})
	}

	const handleAddressFilter = (tag, values, type) => {
		if (values !== "") {
			let searchAddress = [];
			if (type === "select") {
				searchAddress = list.filter((us) => us[tag].toLowerCase() === values.toLowerCase());
			} else {
				searchAddress = list.filter((us) => us[tag] != null ? us[tag].toLowerCase().indexOf(values.toLowerCase()) > -1 : "");
			}
			setlist(searchAddress)
			setProgress(false)
		} else {
			wsGetPractices(props.PracticeID);
		}
	};
	return (
		<>
			<div className='table-main-wrap'>
				<div className="table-link">
					<a href="#!" title="Add Address" onClick={(e) => {
						e.preventDefault();
						setEditData('')
						setModalTitle('Add Address')
						setisModalOpen(true)
					}}>+ Add Address</a>
				</div>
				<PerfectScrollbar style={{ width: '2500px' }} >
					<Table>
						<TableHead >
							<TableRow>
								{headings.map((obj, index) => {
									return <TableCell key={index}>{obj}</TableCell>;
								})}
							</TableRow>
							<TableRow >
								<TableCell>
									<span>
										<img src={filtericon} alt='filter' />
									</span>
								</TableCell>
								<TableCell style={{ width: '10%' }}>
									<input type="text" name="" placeholder="Search Practice Name" onChange={(e) => handleAddressFilter('PracticeName', e.target.value, 'input')} />
								</TableCell>
								<TableCell  >
									<input type="text" name="" placeholder="Search ShipTo" onChange={(e) => handleAddressFilter('ShipToNo', e.target.value, 'input')} />
								</TableCell>
								<TableCell >
									<input type="text" name="" placeholder="Search Representative" onChange={(e) => handleAddressFilter('Representative', e.target.value, 'input')} />
								</TableCell>
								<TableCell >
									<input type="text" name="" placeholder="Search Contact Name" onChange={(e) => handleAddressFilter('ContactName', e.target.value, 'input')} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Address 1" onChange={(e) => handleAddressFilter('Address1', e.target.value, 'input')} />
								</TableCell>
								<TableCell>
									<input type="text" name="" placeholder="Search Address 2" onChange={(e) => handleAddressFilter('Address2', e.target.value, 'input')} />
								</TableCell>
								<TableCell >
									<input type="text" name="" placeholder="Search City" onChange={(e) => handleAddressFilter('City', e.target.value, 'input')} />
								</TableCell>
								<TableCell >
									<input type="text" name="" placeholder="Search State" onChange={(e) => handleAddressFilter('State', e.target.value, 'input')} />
								</TableCell>
								<TableCell >
									<input type="text" name="" placeholder="Search Postalcode" onChange={(e) => handleAddressFilter('Postcode', e.target.value, 'input')} />
								</TableCell>
								<TableCell >
									<input type="text" name="" placeholder="Search Phone Number" onChange={(e) => handleAddressFilter('PhoneNo', e.target.value, 'input')} />
								</TableCell>
								<TableCell >
									<input type="text" name="" placeholder="Email Address" onChange={(e) => handleAddressFilter('EmailAddress', e.target.value, 'input')} />
								</TableCell>
								<TableCell >
									<input type="text" name="" placeholder="Search Vision Source" onChange={(e) => handleAddressFilter('VisionSourceLABEL', e.target.value, 'input')} />
								</TableCell>
								<TableCell >
									<input type="text" name="" placeholder="Search Vsp" onChange={(e) => handleAddressFilter('VSPLABEL', e.target.value, 'input')} />
								</TableCell>
								<TableCell >
									<input type="text" name="" placeholder="Search Website" onChange={(e) => handleAddressFilter('Website', e.target.value, 'input')} />
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!!list.length ? list.map((obj, idnex) => {
								return (
									<TableRow key={idnex}>
										<TableCell>
											<span>
												<img src={editIcon} alt="edit" style={{ cursor: 'pointer', marginRight: '3px' }}
													onClick={() => { setisModalOpen(true); setEditData(obj); setModalTitle('Edit Address') }} />
											</span>
										</TableCell>
										<TableCell>{obj.PracticeName}</TableCell>
										<TableCell>{obj.ShipToNo}</TableCell>
										<TableCell>{obj.Representative}</TableCell>
										<TableCell>{obj.ContactName}</TableCell>
										<TableCell>{obj.Address1}</TableCell>
										<TableCell>{obj.Address2}</TableCell>
										<TableCell>{obj.City}</TableCell>
										<TableCell>{obj.State}</TableCell>
										<TableCell>{obj.Postcode}</TableCell>
										<TableCell>{obj.PhoneNo}</TableCell>
										<TableCell>{obj.EmailAddress}</TableCell>
										<TableCell className={obj.VisionSourceLABEL === "YES" ? 'green' : 'red'}>
											<span>{obj.VisionSourceLABEL === "YES" ? 'Yes' : 'No'}</span>
										</TableCell>
										{/* <TableCell>{obj.VSPLABEL}</TableCell> */}
										<TableCell className={obj.VSPLABEL === "YES" ? 'green' : 'red'}>
											<span>{obj.VSPLABEL === "YES" ? 'Yes' : 'No'}</span>
										</TableCell>
										<TableCell>{obj.Website}</TableCell>
									</TableRow>
								);
							}) : <TableRow>
								<TableCell colSpan={headings.length} className="not_found">
									{progress ?
										<CircularProgress /> :
										<label>Not Currently Available</label>}
								</TableCell>
							</TableRow>}
						</TableBody>
					</Table>

				</PerfectScrollbar>
				{isModalOpen &&
					<ModalComponent show={isModalOpen} handleClose={() => setisModalOpen(false)}>
						<EditPractice
							EditData={EditData}
							Title={ModalTitle}
							ParentCompanyID={props.PracticeID}
							handlebind={() => wsGetPractices(props.PracticeID)}
							closeModal={() => setisModalOpen(false)}
						/>
					</ModalComponent>
				}
			</div>
		</>
	);

};

export default AddressListComponent;
