import React from 'react';

import Table from '@material-ui/core/Table';

const TableComponent = (props) => {
    return (
        <Table className={props.className} classes={props.classes} component={props.component || 'table'} aria-label={props.ariaLabel}
        padding={props.padding || 'default'} size={props.size || 'medium'} stickyHeader={props.stickyHeader}>
            {props.children}
        </Table>
    )
}

export default TableComponent;