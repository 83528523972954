/*eslint-disable eqeqeq*/
import React from "react";
import * as APIController from '../../../api/APIController.js';
import Table from "../../../shared/components/table/table";
import TableHead from "../../../shared/components/table/tableHead";
import TableRow from "../../../shared/components/table/tableRow";
import TableBody from "../../../shared/components/table/tableBody";
import TableCell from "../../../shared/components/table/tableCell";
import double_left_arrow from '../../../assets/images/left-doble-arow.svg';
import left_arrow from '../../../assets/images/left-arrow.svg';
import double_right_arrow from '../../../assets/images/right-doble-arow.svg';
import right_arrow from '../../../assets/images/right-arrow.svg';
import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";

import deleteIcon from '../../../assets/images/delete.svg'
import editIcon from '../../../assets/images/edit.svg'
import filtericon from '../../../assets/images/ic_feather_filter.svg'

import Modal from '../../../shared/components/modal/modal';
import ConfirmtionDialog from '../../ConfirmDialog/ConfirmationDialog';
import Notification from '../../../shared/components/notification/notification';
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";

const CampaignList = (props) => {
    const headerList = ['action', "Brand Name", "Campaign Name", "Total Orders", "Status"]
    const { currentPage, count, rowsPerPage, rowsPerPageOptions, list, to, from } = props;
    let campaignList = list;
    campaignList = list.slice(from, to)
    const [LoginDialog, setLoginDialog] = React.useState(false);
    const [DeleteId, setDeleteId] = React.useState('');

    const page = Math.floor(count / rowsPerPage) + 1;

    /**
     * handleChangeInCurrentPage method is used to change page number
     * it will check that page number does not exist last page
     * @param {*} value 
     */
    const handleChangeInCurrentPage = (value) => {
        if (value <= page && Number(parseInt(value))) {
            props.handleChangePage(Number(value));
        }
    }

    const wsDeleteCampaign = (ID) => {
        APIController.DeleteCampaign(ID)
            .then((response) => {
                if (response.error == null) {
                    if (response.object.status == 1) {
                        props.handleNotification(response.object.message);
                    } else {
                        props.handleNotification(response.object.message, true);
                    }
                    props.handlebind()
                    setLoginDialog(false)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    props.handleNotification(response.error.message)
                }
            })
    }

    const [message, setMessage] = React.useState("");
    const [showNotification, setShowNotification] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const handleClearNotification = () => {
        setMessage('')
        setShowNotification(false)
        setIsError(false)
    }
    return (
        <>
            <h1>View Campaigns</h1>
            <div className="table-main-wrap campaigns-class">
                <PerfectScrollbar component='div'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headerList.map((obj, index) => {
                                    return <TableCell style={index == 0 ? { width: '10%' } : index == index.length - 1 ? { width: '10%' } : { width: '80%' }} className={obj === "Is active ?" ? 'center' : ''} key={index}>{obj}</TableCell>;
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell >
                                    <span><img src={filtericon} alt="delete" /></span>
                                </TableCell>
                                <TableCell>
                                    <input type="text" name="" placeholder="Search Brand" onChange={(e) => props.applyFilter('BrandName', e.target.value, 'input')} />
                                </TableCell>
                                <TableCell>
                                    <input type="text" name="" placeholder="Search Campaign" onChange={(e) => props.applyFilter('Name', e.target.value, 'input')} />
                                </TableCell>
                                <TableCell>
                                    <input type="text" name="" placeholder="Search Orders" onChange={(e) => props.applyFilter('TotalOrders', e.target.value, 'input')} />
                                </TableCell>

                                <TableCell>
                                    <select onChange={(e) => props.applyFilter('status', e.target.value, 'select')}>
                                        <option value="">All</option>
                                        <option value="live">Campaign Live</option>
                                        <option value="inActive">Campaign Inactive</option>
                                        <option value="created">Campaign Created</option>
                                        <option value="asset-uploaded">Asset Uploaded</option>
                                        <option value="raw-asset-uploaded">Raw Asset Uploaded</option>
                                        <option value="in-review">In Review</option>
                                    </select>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!campaignList.length ? campaignList.map((obj, idnex) => {
                                return (
                                    <TableRow key={idnex}>
                                        <TableCell >
                                            <span>
                                                <img src={editIcon} alt="edit" style={{ cursor: 'pointer', marginRight: '3px' }}
                                                    onClick={() => {
                                                        props.history.push({
                                                            pathname: '/campaigns/add',
                                                            state: { campaignIdEdit: obj.ID }
                                                        })
                                                    }} />
                                                <img src={deleteIcon} alt="delete" style={{ cursor: 'pointer', marginRight: '3px' }}
                                                    onClick={() => {
                                                        setDeleteId(obj.ID)
                                                        setLoginDialog(true)
                                                    }} />
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            {obj.BrandName}
                                        </TableCell>
                                        <TableCell>
                                            {obj.Name}
                                        </TableCell>
                                        <TableCell>
                                            {obj.TotalOrders}
                                        </TableCell>
                                        <TableCell className={`${backgroundColor(obj.Status)}`}>
                                            <span>{StatusName(obj.Status)}</span>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <TableRow >
                                <TableCell colSpan={headerList.length} className="not_found">
                                    {props.progress ?
                                        <CircularProgress /> :
                                        <label>Not Currently Available</label>}
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </PerfectScrollbar>
            </div>
            <Notification
                isError={isError}
                message={message}
                showNotification={showNotification}
                closeNotification={handleClearNotification.bind(this)}
            />
            {LoginDialog && (
                <Modal
                    show={LoginDialog}
                    handleClose={() => setLoginDialog(false)}>
                    <ConfirmtionDialog
                        title={"Delete Campaign"}
                        description={"Are you sure you want to delete this Campaign?"}
                        deletePress={() => wsDeleteCampaign(DeleteId)}
                        closeModal={() => setLoginDialog(false)}
                    />
                </Modal>
            )}
            <div className="table-pagination">
                <div className="pagination-info">
                    <p>Showing {from + 1} - {to > count ? count : to} of {count}</p>
                </div>
                <div className="pagination-control">
                    <a href="#!" title="" className={`double-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            handleChangeInCurrentPage(1)
                        }}
                    ><img src={double_left_arrow} alt="left" /></a>
                    <a href="#!" title="" className={`simple-left-arrow ${currentPage === 1 || currentPage === 0 ? 'disabled' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            handleChangeInCurrentPage(currentPage - 1)
                        }}
                    ><img src={left_arrow} alt="single_left" /></a>
                    <span>Page</span>
                    <input type="text" name="textbox" value={currentPage === 0 ? currentPage + 1 : currentPage} onChange={(e) => handleChangeInCurrentPage(e.target.value)} />
                    <span>of {page}</span>
                    <a href="#!" title="" className={`simple-right-arrow ${currentPage === page ? 'disabled' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            handleChangeInCurrentPage(currentPage + 1)
                        }}
                    ><img src={right_arrow} alt="right" /></a>
                    <a href="#!" title="" className={`double-right-arrow ${currentPage === page ? 'disabled' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            handleChangeInCurrentPage(page)
                        }}><img src={double_right_arrow} alt="single_right" /></a>
                    <select onChange={(e) => props.handleChangeRowsPerPage(e.target.value)} defaultValue={rowsPerPage}>
                        {
                            rowsPerPageOptions.map((obj, index) => {
                                return <option value={obj} key={index}>{obj}</option>
                            })
                        }
                    </select>
                </div>
            </div>
        </>
    );
};
const backgroundColor = (status) => {
    let color = '';

    switch (status) {
        case 'NO':
            color = 'red';
            break;
        case 'YES':
            color = 'green';
            break;
        case 'created':
            color = 'purple';
            break;
        case 'asset-uploaded':
            color = 'yellow';
            break;
        case 'raw-asset-uploaded':
            color = 'yellow';
            break;
        case 'in-review':
            color = 'red';
            break;
        default:
            color = 'blue';
            break;
    }
    return color;
};


const StatusName = (status) => {
    let color = '';

    switch (status) {
        case 'NO':
            color = 'Inactive';
            break;
        case 'YES':
            color = 'Live';
            break;
        case 'created':
            color = 'Created';
            break;
        case 'asset-uploaded':
            color = 'Asset Uploaded';
            break;
        case 'raw-asset-uploaded':
            color = 'Raw Asset Uploaded';
            break;
        case 'in-review':
            color = 'In Review';
            break;
        default:
            color = '';
            break;
    }
    return color;
};

export default CampaignList;
