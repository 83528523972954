import React from 'react'
import { Typography, Grid } from '@material-ui/core';
import * as APIController from "../../../../src/api/APIController";
import profile_generic from "../../../assets/images/profile_generic.png";
import CircularProgress from "../../../shared/components/CircularProgress/CircularProgress";
import PageTitle from "../../../config/pageTitle";
import * as AppConsatnt from "../../../config/AppConstant";

const ContactUs = (props) => {

    const [VTMName, setVTMName] = React.useState("");
    const [VTMEmail, setVTMEmail] = React.useState("")
    const [VTMPhone, setVTMPhone] = React.useState("")
    const [IsLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        wsGetMyProfile()
    }, [props])

    const wsGetMyProfile = () => {
        setIsLoading(true)
        APIController.GetMyProfile()
            .then((response) => {
                if (response.error == null) {
                    setVTMName(response.object.data[0].VTMName)
                    setVTMEmail(response.object.data[0].VTMEmail)
                    setVTMPhone(response.object.data[0].VTMPhone)
                }
                else if (response.error.message && response.error.message.length > 0) {
                    // handleNotification(response.error.message, true)
                }
                setIsLoading(false)
            })
    }

    return (
        <>
            <section className='content-right-main'>
                <PageTitle Title={AppConsatnt.ContactDetais.HowToReachUs} />
                <div className='two-col-sec'>
                    <nowrap>
                        <div style={{ fontSize: '16px', padding: '0px 0px 5px' }}>{AppConsatnt.CommanText.ContactUsLine1}</div>
                        <Grid container direction='row' >
                            <Grid container direction='column' style={{ justifyContent: 'center', alignItems: 'center', width: '18%', padding: '15px 15px 10px' }}>
                                <img
                                    height={100}
                                    width={100}
                                    src={profile_generic}
                                    alt='preview-imag'
                                />
                            </Grid>
                            {IsLoading ? <Grid container direction='column' style={{ justifyContent: 'center', alignItems: 'center', width: '80%', }}>
                                <CircularProgress />
                            </Grid> :
                                <Grid container direction='column' style={{ width: '80%', padding: '15px 15px 10px', }}>
                                    <Typography style={{ fontWeight: 'bold', fontSize: '18px', letterSpacing: '0.3px', color: '#000000' }}>{VTMName}</Typography>
                                    <Typography style={{ fontSize: '16px', letterSpacing: '0.3px' }}>{AppConsatnt.CommanText.ContactUsTerritory}</Typography>
                                    <Typography style={{ fontSize: '16px', letterSpacing: '0.3px', color: '#0a5c7f' }}><a href={"mailto:" + VTMEmail} >{VTMEmail}</a></Typography>
                                    <Typography style={{ fontSize: '16px', letterSpacing: '0.3px' }}>{VTMPhone}</Typography>
                                </Grid>
                            }
                        </Grid>
                        {AppConsatnt.GetMainClassName() == "app-main" ? <>
                            <div style={{ marginTop: '10px', fontSize: '16px', padding: '0px' }}>{AppConsatnt.CommanText.ContactUsLine2}</div>
                            <div style={{ marginTop: '20px', fontSize: '18px', padding: '0px' }}>{AppConsatnt.CommanText.ContactUsLine3}</div>
                            <div>Email: <a href={"mailto:" + AppConsatnt.CommanText.ContactUsEmail1} style={{ color: '#0a5c7f' }}>{AppConsatnt.CommanText.ContactUsEmail1}</a></div>
                        </> : <>
                            <div style={{ marginTop: '10px', fontSize: '16px', padding: '0px' }}>{AppConsatnt.CommanText.ContactUsLine2}
                                <a href={"mailto:" + AppConsatnt.CommanText.ContactUsEmail1} style={{ color: '#2d89d8' }}>{AppConsatnt.CommanText.ContactUsEmail1}</a></div>
                            <div style={{ marginTop: '20px', fontSize: '16px', padding: '0px' }}>{AppConsatnt.CommanText.ContactUsLine3}
                                <a href={"mailto:" + AppConsatnt.CommanText.ContactUsEmail2} style={{ color: '#2d89d8' }}>{AppConsatnt.CommanText.ContactUsEmail2}</a></div> </>}
                    </nowrap>
                </div>
            </section>
        </>
    );
}

export default ContactUs